<template>
  <div class="recent-insights widget half">
    <div>
      <h2 class="title">Recent Insights</h2>
      <ul>
        <li class="insight-item">
          <DisplaySvg name="warning" class="icon-circle" />
          <p>
            Compared to the last 3 months, your consent score has dropped and
            two tags continue to collect data about users after they oped out
            from being tracked.
            <LinkWithIcon
              icon="link"
              v-bind:right="true"
              text="Learn More"
              link="/"
            />
          </p>
        </li>
        <li class="insight-item">
          <DisplaySvg name="bar-graph-data-up" class="icon-circle" />
          <p>
            Your Privacy Score across the sites managed by Agency A has
            increased by 4% on average.
            <LinkWithIcon
              icon="link"
              v-bind:right="true"
              text="Learn More"
              link="/"
            />
          </p>
        </li>
        <li class="insight-item">
          <DisplaySvg name="line-graph-data-up" class="icon-circle" />
          <p>
            Brand 1 has seen the biggest improvement within your portfolio over
            the past 60 days.
            <LinkWithIcon
              icon="link"
              v-bind:right="true"
              text="Learn More"
              link="/"
            />
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";

export default {
  name: "RecentInsights",
  components: {
    DisplaySvg,
    LinkWithIcon,
  },
  props: [],
  data() {
    return {
      insights: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.recent-insights > div {
  width: 100%;

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
    text-align: left;
  }

  ul {
    margin: 0;
    padding: 0;
  }
}

.insight-item {
  display: flex;
  margin-bottom: 20px;

  > div {
    margin-top: 3px;
  }

  p {
    margin: 0 0 0px 16px;
  }

  &:last-child {
    margin: 0;
  }
}
</style>
