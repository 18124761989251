<template>
  <section class="auth-credentials view-container">
    <section class="view-header">
      <h1 class="view-title">Manage Account</h1>
      <div class="view-sublinks">
        <LinkWithIcon
          icon="profile"
          text="Profile"
          link="/manage-account/profile"
          className="profile-icon"
        />
        <LinkWithIcon
          icon="users"
          text="User Management"
          link="/manage-account/user-management"
          className="users-icon"
        />
        <LinkWithIcon
          icon="lock"
          text="Credential Management"
          link="/manage-account/credential-management"
          className="lock-icon"
          v-bind:active="true"
        />
      </div>
    </section>

    <Overlay v-if="isAddCredOverlayOpen" :closeClick="closeCredOveraly">
      <div class="widget add-cred-overlay">
        <div @click="closeCredOveraly()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <AddCredForm
          :closeClick="closeCredOveraly"
          :name="isAddCredOverlayOpen.name"
          :type="isAddCredOverlayOpen.type"
          :orgid="isAddCredOverlayOpen.orgid"
          :status="isAddCredOverlayOpen.status"
          :statusDetail="isAddCredOverlayOpen.statusDetail"
          :credentialid="isAddCredOverlayOpen.credentialid"
          :rootClientId="rootClientId"
        />
      </div>
    </Overlay>

    <Overlay v-if="isRemoveCredOverlayOpen" :closeClick="closeCredOveraly">
      <div class="widget add-cred-overlay">
        <div @click="closeCredOveraly()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <RemoveCredForm
          :closeClick="closeCredOveraly"
          :name="isRemoveCredOverlayOpen.name"
          :type="isRemoveCredOverlayOpen.type"
          :orgid="isRemoveCredOverlayOpen.orgid"
          :status="isRemoveCredOverlayOpen.status"
          :statusDetail="isRemoveCredOverlayOpen.statusDetail"
          :credentialid="isRemoveCredOverlayOpen.credentialid"
          :rootClientId="rootClientId"
        />
      </div>
    </Overlay>

    <div class="workarea">
      <div class="widget full auth-credentials">
        <div class="table-wrapper">
          <div class="table-header cred-table-header">
            <div class="manage-account-cred-title">
              <h2>Auth Credentials</h2>
              <p>
                Manage your organization's authentication credentials for AdTech
                DNA. These credentials allow us to access your external
                marketing services safely and securely.
              </p>
            </div>
            <div class="filters" v-if="$store.state.organizationOptions">
              <UpdateOrgForm />
            </div>
          </div>
          <div class="table-header cred-table-sub-header">
            <div class="manage-account-cred-title">
              <h3 v-html="hierarchyString"></h3>
            </div>
            <div class="filters" v-if="hasAdminAccessForCurrentOrg && !loading">
              <NInput
                v-model:value="searchQuery"
                placeholder="Search"
                v-if="tableData.length > 0 && !loading"
              >
                <template #suffix>
                  <DisplaySvg name="search" class="search-icon" />
                </template>
              </NInput>
              <button
                class="button blue-btn"
                :onClick="
                  () => {
                    addCredential(false);
                  }
                "
              >
                Add Credential <DisplaySvg name="plus" class="plus-icon" />
              </button>
            </div>
          </div>
        </div>
        <NDataTable
          v-if="
            tableData.length > 0 &&
            hasAdminAccessForCurrentOrg &&
            !error &&
            !loading
          "
          :columns="tableColumns"
          :data="filteredData"
          :bordered="false"
          :pagination="pagination"
          class="full manage-account-creds--users-table"
        ></NDataTable>
        <div class="site-error" v-if="!hasAdminAccessForCurrentOrg && !loading">
          You do not have admin access to the
          {{ $store.state.organization }} organization.
        </div>
        <div
          class="site-message"
          v-if="
            tableData.length == 0 && hasAdminAccessForCurrentOrg && !loading
          "
        >
          The {{ $store.state.organization }} organization currently has no
          credentials at the base level.
        </div>
        <div class="site-error" v-if="error && !loading">{{ error }}</div>
        <div class="site-loading" v-if="!error && loading">
          <LoadingSpinner /> Loading...
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import router from "@/router";
import CredentialService from "@/services/CredentialService";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import Overlay from "@/components/Shared/Overlay.vue";
import AddCredForm from "@/components/ManageAccount/AddCredForm.vue";
import RemoveCredForm from "@/components/ManageAccount/RemoveCredForm.vue";
import UpdateOrgForm from "@/components/ManageAccount/UpdateOrgForm.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NDataTable, NInput } from "naive-ui";
import { h } from "vue";
import { getNestedOrgs } from "@/Helpers.js";

export default {
  name: "ManageAccountCredentials",
  components: {
    LinkWithIcon,
    NDataTable,
    NInput,
    DisplaySvg,
    AddCredForm,
    RemoveCredForm,
    Overlay,
    UpdateOrgForm,
    LoadingSpinner,
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: 10,
        disabled: this.filteredData.length <= 10 ? true : false,
      };
    },
  },
  methods: {
    addCredential(cred) {
      this.isAddCredOverlayOpen = {
        type: cred ? cred.type : false,
        name: cred ? cred.name : false,
        status: cred ? cred.status : false,
        statusDetail: cred ? cred.statusDetail : false,
        credentialid: cred ? cred.credentialid : false,
        orgid: this.$store.state.organization,
      };
    },
    removeCredential(cred) {
      this.isRemoveCredOverlayOpen = {
        type: cred ? cred.type : false,
        name: cred ? cred.name : false,
        status: cred ? cred.status : false,
        statusDetail: cred ? cred.statusDetail : false,
        credentialid: cred ? cred.credentialid : false,
        orgid: this.$store.state.organization,
      };
    },
    closeCredOveraly() {
      this.isAddCredOverlayOpen = false;
      this.isRemoveCredOverlayOpen = false;
      this.getData();
    },
    getData() {
      if (this.getDataActive) {
        // cancel function if it's currently running
        // prevents multiple calls from going out
        return;
      }

      this.getDataActive = true;
      this.loading = true;

      if (process.env.NODE_ENV === "demo") {
        this.hierarchyString = "Root &#x2022; Demo";
        this.hasAdminAccessForCurrentOrg = true;
        this.tableData = [
          {
            name: "Demo Credential Auth: kevin.breneman@infotrustllc.com",
            owner: "Kevin Breneman",
            type: "google - oauth",
            status: "PENDING",
            statusDetail: "needs authenticated",
            credentialid: "CRED-CGVK",
            self: false,
          },
          {
            name: "Google service account: christopher.breneman@infotrustllc.com",
            owner: "Christopher Breneman",
            type: "google - service account",
            status: "ACTIVE",
            statusDetail: null,
            credentialid: "CRED-CMW3",
            self: false,
          },
        ];
        this.setTableColumns();
        this.getDataActive = false;
        this.loading = false;
        return;
      }

      // before proceeding, we wanna make sure this user
      // has access to see the manage users url
      let hasAdminAccess = false;
      this.$store.state.organizationOptions.map((org) => {
        if (org.role.toLowerCase() === "admin" && hasAdminAccess === false) {
          hasAdminAccess = true;
        }
      });

      if (!hasAdminAccess) {
        return router.push("/manage-account/profile");
      }

      const currentOrgIndex = this.$store.state.organizationOptions.findIndex(
        (x) => x.value === this.$store.state.organization
      );
      const currentOrg = this.$store.state.organizationOptions[currentOrgIndex];
      let tableData = [];

      // get the org hierarchy string
      const divider = "  &#x2022; ";
      let nestedObj = getNestedOrgs(
        currentOrg,
        this.$store.state.organizationOptions,
        this.$store.state.orgHierarchy
      );
      let reversedNestedArray = [].concat(nestedObj.nested_array).reverse();
      const hStringValue = reversedNestedArray.join(divider);
      this.hierarchyString = hStringValue;

      // if the current user is not an admin for this org, do not proceed
      if (currentOrg.role !== "Admin") {
        this.loading = false;
        this.getDataActive = false;
        return (this.hasAdminAccessForCurrentOrg = false);
      } else {
        this.hasAdminAccessForCurrentOrg = true;
      }

      CredentialService.call("credentials.list", {
        orgid: currentOrg.value,
      }).then((response) => {
        const userId = this.$store.state.userData.userid;
        const promiseArray = response.result.credentials.map((cred) => {
          return CredentialService.call("users.get", {
            userid: cred.owner,
          }).then((userResponse) => {
            let type = "";
            if (cred.type === "oauthuser") {
              type = `${cred.service} - oauth`;
            } else if (cred.type === "serviceacct") {
              type = `${cred.service} - service account`;
            } else {
              type = `${cred.service} - ${cred.type}`;
            }

            let user = { userid: cred.owner };
            if (userResponse?.result?.user) {
              user.name = userResponse.result.user.name;
            }

            // we don't want to display anything that's not
            // a google oauth or service account at this time
            if (
              (cred.type === "oauthuser" || cred.type === "serviceacct") &&
              cred.service === "google"
            ) {
              tableData.push({
                name: cred.name,
                owner: user.name,
                type: type,
                status: cred.pending_reauth ? "PENDING" : "ACTIVE",
                statusDetail: cred.pending_reauth
                  ? "needs authenticated"
                  : null,
                credentialid: cred.credentialid,
                self: cred.owner == userId,
              });
            }
          });
        });

        Promise.all(promiseArray).then(() => {
          this.tableData = tableData;
          this.loading = false;
          this.getDataActive = false;
        });
      });

      this.setTableColumns();
    },
    setTableColumns() {
      const editCredOnClick = (cred) => {
        this.addCredential(cred);
      };
      const removeCredOnClick = (cred) => {
        this.removeCredential(cred);
      };

      this.tableColumns = [
        {
          title: "Name",
          render(row) {
            return h(
              "p",
              {
                style: {},
              },

              row.name
            );
          },
        },
        {
          title: "Owner",
          minWidth: 200,
          render(row) {
            return h("p", {}, row.owner);
          },
        },
        {
          title: "Type",
          minWidth: 200,
          render(row) {
            return h("p", {}, row.type);
          },
        },
        {
          title: "Status",
          minWidth: 200,
          render(row) {
            return h("div", {}, [
              h(
                "p",
                { class: `${row.status === "ACTIVE" ? "green" : "orange"}` },
                row.status
              ),
              h("p", { class: "gray" }, row.statusDetail),
            ]);
          },
        },
        {
          title: "",
          key: "action",
          minWidth: 100,
          render(row) {
            const editOnClick = () => {
              editCredOnClick(row);
            };
            const removeOnClick = () => {
              removeCredOnClick(row);
            };

            const options = [];

            if (row.self && row.statusDetail === "needs authenticated") {
              options.push({
                key: `${row.name
                  .toLowerCase()
                  .split(" ")
                  .join("-")}-authenticate`,
                onClick: editOnClick,
                text: "Authenticate",
              });
            } else if (
              row.self &&
              `${row.type}`.toLowerCase() === "google - oauth"
            ) {
              options.push({
                key: `${row.name
                  .toLowerCase()
                  .split(" ")
                  .join("-")}-view-details`,
                onClick: editOnClick,
                text: "View Details",
              });
            } else if (
              row.self &&
              `${row.type}`.toLowerCase() === "google - service account"
            ) {
              options.push({
                key: `${row.name.toLowerCase().split(" ").join("-")}-resubmit`,
                onClick: editOnClick,
                text: "Resubmit",
              });
            } else if (row.self) {
              options.push({
                key: `${row.name.toLowerCase().split(" ").join("-")}-edit`,
                onClick: editOnClick,
                text: "Edit",
              });
            }

            if (row.self) {
              options.push({
                key: `${row.name.toLowerCase().split(" ").join("-")}-remove`,
                onClick: removeOnClick,
                text: "Remove",
                class: "red",
              });
            }

            if (options.length > 0) {
              return h(
                EllipsisDropdown,
                {
                  options: options,
                },
                []
              );
            }
            return "";
          },
        },
      ];
    },
  },
  data() {
    return {
      error: false,
      loading: true,
      getDataActive: false, // only used to tell if the current getData call is running
      NODE_ENV: process.env.NODE_ENV,
      isAddCredOverlayOpen: false,
      isRemoveCredOverlayOpen: false,
      searchQuery: "",
      tableColumns: [],
      tableData: [],
      hasAdminAccessForCurrentOrg: false,
      hierarchyString: "root",
      rootClientId: null,
    };
  },
  watch: {
    "$store.state.orgHierarchy": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.organizationOptions": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.userData": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    "$store.state.organization": function () {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "demo") {
      return this.getData();
    }

    // on mount get the client id for the root org
    // this is needed when added a new credential
    CredentialService.call("credentials.google.listClientIds", {
      orgid: "root",
    }).then((response) => {
      const clientIds =
        response && response.result ? response.result.clientIds : [];
      let rootClientId = null;
      clientIds.map((item) => {
        if (item.tags.includes("RootClientId")) {
          rootClientId = item.credentialid;
        }
      });

      this.rootClientId = rootClientId;

      if (
        this.$store.state.organizationOptions &&
        this.$store.state.organizationOptions.length > 0 &&
        this.$store.state.organization &&
        this.$store.state.userData &&
        this.$store.state.orgHierarchy &&
        this.$store.state.orgHierarchy.length > 0
      ) {
        this.getData();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.auth-credentials {
  display: block;

  .view-title,
  .title {
    margin: 0;
    width: 100%;
    text-align: left;
  }

  .manage-account-cred-title {
    width: 50%;
    h2 {
      color: $navy;
      text-align: left;
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    h3 {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      color: $dark-gray;
      font-weight: 500;
    }

    @include media($large-lowest, down) {
      width: 100%;
    }
  }

  .description {
    width: 100%;
    text-align: left;
    max-width: 600px;
    margin-bottom: 50px;
  }
  .search-icon {
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
    color: $dark-gray;
  }
  .blue-btn {
    background-color: $blue;
    color: $white;
    @include hover {
      color: $white;
      background-color: $navy !important;
    }
  }
  .close-icon {
    height: 16px;
    width: 16px;
    color: $dark-gray;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .plus-icon {
    color: $white;
    height: 14px;
    width: 14px;
    margin-left: 10px;
  }
  .table-header {
    justify-content: flex-end;
  }

  .add-cred-overlay {
    h2 {
      text-align: left;
    }
    width: calc(100vw - 40px);
    max-width: 550px;
    padding-top: 40px;
  }

  .cred-table-header {
    border-bottom: 1px solid $dark-gray;
    padding-bottom: 50px;
    margin-bottom: 50px;

    @include media($large-lowest, down) {
      .filters {
        justify-content: flex-start;
      }
    }

    .select-inset-label {
      max-width: 250px;
    }
  }
  .cred-table-sub-header {
    justify-content: space-between;
  }
}
</style>
