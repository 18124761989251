<template>
  <div class="view-container platform-governance-violations">
    <section class="view-header">
      <div class="half">
        <div class="title-eyebrow">
          <router-link to="/platform-governance/overview" class="eyebrow-link">
            Platform Governance &amp; Standards
          </router-link>
        </div>
        <div class="view-title">Violations</div>
      </div>
      <div class="half"></div>
    </section>
    <div class="workarea">
      <div class="widget full">
        <div v-if="error || loading">
          <div class="site-error" v-if="error || !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>

        <div class="table-wrapper" v-if="!error && !loading">
          <div class="table-header">
            <div class="title"></div>
            <div class="filters">
              <a
                href=""
                class="button filter-button"
                v-if="NODE_ENV === 'demo'"
              >
                <DisplaySvg name="filter" class="filter-icon" />
                Filter
              </a>
              <NInput v-model:value="searchQuery" placeholder="Search"></NInput>
            </div>
          </div>
          <NDataTable
            :columns="tableColumns"
            :data="filteredData"
            :bordered="false"
            :pagination="pagination"
            class="full"
          ></NDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import AdtechService from "@/services/AdtechService";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { NDataTable, NInput } from "naive-ui";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getDashboardErrorMessage } from "@/ErrorMessaging";
import store from "@/store/index.js";
import { h } from "vue";

import ruleViolationsFixture from "@/fixtures/ruleViolations.json";

export default {
  name: "PlatformGovernanceViolationsView",
  components: {
    DisplaySvg,
    NDataTable,
    NInput,
    LoadingSpinner,
  },
  watch: {
    "$store.state.scans": function (newScans) {
      this.$nextTick(async () => {
        if (newScans && newScans.error) {
          this.loading = false;
          this.error = newScans.error;
        }

        if (newScans && newScans.length > 0) {
          this.getViolations();
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/platform-governance/overview");
      }
    },
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: 10,
        disabled: this.filteredData.length <= 10 ? true : false,
      };
    },
  },
  data() {
    const tableColumns = [
      {
        title: "Severity",
        key: "severity",
        minWidth: 100,
        render(row) {
          if (row.severity == "0") {
            return h("div", { class: "info" }, "Info");
          } else if (row.severity == "1") {
            return h("div", { class: "low" }, "Low");
          } else if (row.severity == "2") {
            return h("div", { class: "medium" }, "Medium");
          } else if (row.severity == "3") {
            return h("div", { class: "high" }, "High");
          } else if (row.severity == "4") {
            return h("div", { class: "severe" }, "Severe");
          } else if (row.severity == "5") {
            return h("div", { class: "critical" }, "Critical");
          }
        },
      },
      {
        title: "Site",
        minWidth: 100,
        key: "containerid",
        render(row) {
          return store.getters.getContainerMeta(row.containerid, "name");
        },
      },
      {
        title: "Type",
        key: "violationcode",
        minWidth: 260,
      },
    ];

    if (process.env.NODE_ENV === "demo") {
      tableColumns.push({
        title: "Category",
        width: 100,
        render(row) {
          return row.category;
        },
      });
      tableColumns.push({
        title: "Reported",
        width: 100,
        render(row) {
          return row.reported;
        },
      });
    }

    tableColumns.push({
      title: "Rule Violated",
      key: "message",
    });

    const orgId = this.$store.state.organization;

    tableColumns.push({
      title: "",
      key: "action",
      render(row) {
        return h(
          EllipsisDropdown,
          {
            options: [
              {
                key: row.ruleinstanceid,
                link: `/platform-governance/violations/${
                  row.ruleinstanceid
                }?containerid=${row.containerid}&violationnum=${
                  row.violationnum
                }&scanid=${row.scanid}&entityid=${row.entityid}&org=${encodeURIComponent(orgId)}`,
                text: "View Details",
              },
            ],
          },
          []
        );
      },
    });

    return {
      NODE_ENV: process.env.NODE_ENV,
      searchQuery: "",
      tableColumns: tableColumns,
      tableData: [],
      error: null,
      loading: true,
    };
  },
  methods: {
    getViolations() {
      if (
        this.$store.state.scans &&
        this.$store.state.scans[0] &&
        process.env.NODE_ENV !== "demo"
      ) {
        AdtechService.call("ruleviolations.list", {
          orgid: this.$store.state.organization,
          scanid: this.$store.state.scans[0].scanid,
        })
          .then((response) => {
            if (response.error) {
              this.triggerError({
                message: "ruleviolations.list - error obj returned from api",
                error: response.error,
              });
              return Promise.resolve();
            }

            this.tableData = response.result.violations;
            this.loading = false;
          })
          .catch((error) => {
            this.triggerError({
              message: "ruleviolations.list - catch",
              error: error,
            });
          });
      }

      if (process.env.NODE_ENV === "demo") {
        this.tableData = ruleViolationsFixture;
        this.loading = false;
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getDashboardErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  mounted() {
    if (this.$store.state.scans && this.$store.state.scans.error) {
      this.loading = false;
      this.error = this.$store.state.scans.error;
    }

    if (this.$store.state.scans) {
      this.getViolations();
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
};
</script>

<style lang="scss">
.platform-governance-violations {
  .view-title {
    margin: 30px 0px 20px 0;
  }

  .widget {
    display: block;
  }

  .table-wrapper {
    min-height: 650px;
  }

  .info {
    color: $dark-gray;
  }

  .low,
  .medium {
    color: $yellow;
  }

  .high,
  .severe {
    color: $orange;
  }

  .critical {
    color: $red;
  }

  .table-wrapper {
    min-width: 875px;
  }

  .search-icon {
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
    color: $dark-gray;
  }

  .filter-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  .filter-button {
    margin-right: 20px;
  }
}
</style>
