<template>
  <div class="view-container platform-governance">
    <section class="view-header">
      <div class="view-title">
        Platform Governance &amp; Standards - Executive Dashboard
      </div>
      <div class="view-sublinks" v-if="!error && !loading">
        <LinkWithIcon
          icon="bar-plus-line-graph-data-up"
          text="Overview"
          link="/platform-governance/overview"
          v-bind:active="true"
        />
        <LinkWithIcon
          icon="list-ui"
          text="Details"
          :link="`/platform-governance/details?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
      </div>
    </section>
    <div class="widget full" v-if="error || loading">
      <div class="site-error" v-if="error && !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div class="workarea" v-if="!loading && !error">
      <PerformanceByCategoryGraphWidget size="half" />
      <LeaderboardWidget
        title="Leaderboard"
        :scans="$store.state.scans"
        size="half"
      />
      <TotalViolationsWidget :scans="$store.state.scans" size="half" />
      <ImprovementMonitoringWidget size="half" />
      <GovernanceScoreboardWidget :scans="$store.state.scans" />
    </div>
  </div>
</template>

<script>
import router from "@/router";
import TotalViolationsWidget from "@/components/PlatformGovernance/TotalViolationsWidget";
import ImprovementMonitoringWidget from "@/components/PlatformGovernance/ImprovementMonitoringWidget";
import GovernanceScoreboardWidget from "@/components/PlatformGovernance/GovernanceScoreboardWidget";
import PerformanceByCategoryGraphWidget from "@/components/PlatformGovernance/PerformanceByCategoryGraphWidget";
import LeaderboardWidget from "@/components/Shared/LeaderboardWidget";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getDashboardErrorMessage } from "@/ErrorMessaging";

export default {
  name: "PlatformGovernanceView",
  data() {
    return {
      authCredentials: [],
      error: null,
      loading: true,
    };
  },
  components: {
    TotalViolationsWidget,
    ImprovementMonitoringWidget,
    GovernanceScoreboardWidget,
    PerformanceByCategoryGraphWidget,
    LeaderboardWidget,
    LinkWithIcon,
    LoadingSpinner,
  },
  methods: {},
  mounted() {
    // TODO: do we need these?
    this.$store.dispatch("getScans");
    this.$store.dispatch("getContainers");

    if (this.$store.state.scans && this.$store.state.containers) {
      this.loading = false;
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
  watch: {
    "$store.state.containers": function (newContainers) {
      this.$nextTick(async () => {
        const newScans = this.$store.state.scans;
        if (newScans && newScans.length > 0 && newContainers) {
          this.loading = false;
        } else if (newContainers && newContainers.error) {
          this.error = newContainers.error;
          this.loading = false;
        } else if (newContainers && newScans && newScans.length === 0) {
          this.error = getDashboardErrorMessage(this.$store.state.organization);
          this.loading = false;
        }
      });
    },
    "$store.state.scans": function (newScans) {
      this.$nextTick(async () => {
        const newContainers = this.$store.state.containers;
        if (newScans && newScans.length > 0 && newContainers) {
          this.loading = false;
        } else if (newScans && newScans.error) {
          this.error = newScans.error;
          this.loading = false;
        } else if (newContainers && newScans && newScans.length === 0) {
          this.error = getDashboardErrorMessage(this.$store.state.organization);
          this.loading = false;
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
