<template>
  <div class="tag-consent-overview widget full">
    <h2 class="title">Consent Violations by Consent Type</h2>
    <div v-if="error || loading">
      <!-- <div class="site-error" v-if="error || !loading">{{ error }}</div> -->
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div
      class="tag-consent-overview-wrapper"
      v-if="
        !error &&
        !loading &&
        aggregatedData['tags'] != null &&
        aggregatedData['sites'] != null
      "
    >
      <div class="tag-consent-pie-section">
        <h3><span>Consent Type</span> Global Privacy Control</h3>
        <div>
          <div>
            <DoughnutChartScore
              :score="[aggregatedData['tags']['Global Privacy Control'].violating, aggregatedData['tags']['Global Privacy Control'].nonviolating]"
              :displayNumber="
                aggregatedData['tags']['Global Privacy Control'].violating
              "
              :highNumBad="true"
              size="medium"
            />
            <p>
              <strong>
                {{ aggregatedData["tags"]["Global Privacy Control"].violating }}
                Violating Tag{{
                  aggregatedData["tags"]["Global Privacy Control"].violating ===
                  1
                    ? ""
                    : "s"
                }}
              </strong>
            </p>
            <p>
              {{
                aggregatedData["tags"]["Global Privacy Control"].violating +
                aggregatedData["tags"]["Global Privacy Control"].nonviolating
              }}
              Total Tag{{
                aggregatedData["tags"]["Global Privacy Control"].violating +
                  aggregatedData["tags"]["Global Privacy Control"]
                    .nonviolating ===
                1
                  ? ""
                  : "s"
              }}
            </p>
          </div>
          <div>
            <DoughnutChartScore
              :score="[aggregatedData['sites']['Global Privacy Control'].violating, aggregatedData['sites']['Global Privacy Control'].nonviolating]"
              :highNumBad="true"
              :displayNumber="
                aggregatedData['sites']['Global Privacy Control'].violating
              "
              size="medium"
            />
            <p>
              <strong>
                {{
                  aggregatedData["sites"]["Global Privacy Control"].violating
                }}
                Violating Site{{
                  aggregatedData["sites"]["Global Privacy Control"]
                    .violating === 1
                    ? ""
                    : "s"
                }}
              </strong>
            </p>
            <p>
              {{
                aggregatedData["sites"]["Global Privacy Control"].violating +
                aggregatedData["sites"]["Global Privacy Control"].nonviolating
              }}
              Total Site{{
                aggregatedData["sites"]["Global Privacy Control"].violating +
                  aggregatedData["sites"]["Global Privacy Control"]
                    .nonviolating ===
                1
                  ? ""
                  : "s"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tag-consent-pie-section">
        <h3><span>Consent Type</span> All Enabled</h3>
        <div>
          <div>
            <DoughnutChartScore
              :score="[aggregatedData['tags']['None Enabled'].violating, aggregatedData['tags']['None Enabled'].nonviolating]"
              :displayNumber="aggregatedData['tags']['All Enabled'].violating"
              :highNumBad="true"
              size="medium"
            />
            <p>
              <strong>
                {{ aggregatedData["tags"]["All Enabled"].violating }} Violating
                Tag{{
                  aggregatedData["tags"]["All Enabled"].violating === 1
                    ? ""
                    : "s"
                }}
              </strong>
            </p>
            <p>
              {{
                aggregatedData["tags"]["All Enabled"].violating +
                aggregatedData["tags"]["All Enabled"].nonviolating
              }}
              Total Tag{{
                aggregatedData["tags"]["All Enabled"].violating +
                  aggregatedData["tags"]["All Enabled"].nonviolating ===
                1
                  ? ""
                  : "s"
              }}
            </p>
          </div>
          <div>
            <DoughnutChartScore
              :score="[aggregatedData['sites']['All Enabled'].violating, aggregatedData['sites']['All Enabled'].nonviolating]"
              :highNumBad="true"
              :displayNumber="aggregatedData['sites']['All Enabled'].violating"
              size="medium"
            />
            <p>
              <strong>
                {{ aggregatedData["sites"]["All Enabled"].violating }} Violating
                Site{{
                  aggregatedData["sites"]["All Enabled"].violating === 1
                    ? ""
                    : "s"
                }}
              </strong>
            </p>
            <p>
              {{
                aggregatedData["sites"]["All Enabled"].violating +
                aggregatedData["sites"]["All Enabled"].nonviolating
              }}
              Total Site{{
                aggregatedData["sites"]["All Enabled"].violating +
                  aggregatedData["sites"]["All Enabled"].nonviolating ===
                1
                  ? ""
                  : "s"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="tag-consent-pie-section">
        <h3><span>Consent Type</span> None Enabled</h3>
        <div>
          <div>
            <DoughnutChartScore
              :score="[aggregatedData['tags']['None Enabled'].violating, aggregatedData['tags']['None Enabled'].nonviolating]"
              :displayNumber="aggregatedData['tags']['None Enabled'].violating"
              :highNumBad="true"
              size="medium"
            />
            <p>
              <strong>
                {{ aggregatedData["tags"]["None Enabled"].violating }} Violating
                Tag{{
                  aggregatedData["tags"]["None Enabled"].violating === 1
                    ? ""
                    : "s"
                }}
              </strong>
            </p>
            <p>
              {{
                aggregatedData["tags"]["None Enabled"].violating +
                aggregatedData["tags"]["None Enabled"].nonviolating
              }}
              Total Tag{{
                aggregatedData["tags"]["None Enabled"].violating +
                  aggregatedData["tags"]["None Enabled"].nonviolating ===
                1
                  ? ""
                  : "s"
              }}
            </p>
          </div>
          <div>
            <DoughnutChartScore
              :score="[aggregatedData['sites']['None Enabled'].violating, aggregatedData['sites']['None Enabled'].nonviolating]
              "
              :highNumBad="true"
              :displayNumber="aggregatedData['sites']['None Enabled'].violating"
              size="medium"
            />
            <p>
              <strong>
                {{ aggregatedData["sites"]["None Enabled"].violating }}
                Violating Site{{
                  aggregatedData["sites"]["None Enabled"].violating === 1
                    ? ""
                    : "s"
                }}
              </strong>
            </p>
            <p>
              {{
                aggregatedData["sites"]["None Enabled"].violating +
                aggregatedData["sites"]["None Enabled"].nonviolating
              }}
              Total Site{{
                aggregatedData["sites"]["None Enabled"].violating +
                  aggregatedData["sites"]["None Enabled"].nonviolating ===
                1
                  ? ""
                  : "s"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChartScore from "@/components/Shared/DoughnutChartScore.vue";
import MetadataService from "@/services/MetadataService";
import { getConsentErrorMessage } from "@/ErrorMessaging";
import TagConsentFixture from "@/fixtures/consentOverview.json";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { normalizeTagConsentResults } from "@/Helpers.js";

export default {
  name: "TagConsentOverview",
  components: { DoughnutChartScore, LoadingSpinner },
  watch: {
    "$store.state.containers": function () {
      if (
        this.$store.state.containers &&
        this.$store.state.containers.length !== 0
      ) {
        this.getData();
      }
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      aggregatedData: {
        sites: null,
        tags: null,
      },
    };
  },
  methods: {
    queryTags() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - queryTags - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_tag_aggregated__
          `,
      });
    },
    querySites() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - querySites - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_site_aggregated__
          `,
      });
    },
    getData() {
      this.loading = true; // Ensure loading is true at the beginning

      Promise.all([this.queryTags(), this.querySites()])
        .then((responses) => {
          const tagsResponse = responses[0]; // tags
          const sitesResponse = responses[1]; // sites

          if (!tagsResponse || !sitesResponse) {
            this.triggerError({
              message: "runConsentMonitoringQuery - TagConsentOverviewWidget",
              info: "Tags Response or Sites Response is undefined",
            });
            return Promise.resolve();
          }

          if (tagsResponse.error || !tagsResponse.result) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentOverviewWidget - error obj returned from api",
              error: tagsResponse.error,
              info: !tagsResponse.result
                ? "Tags Response No Result Obj Returned"
                : "Tags Response Error",
            });
            return Promise.resolve();
          }

          if (sitesResponse.error || !sitesResponse.result) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentOverviewWidget - error obj returned from api",
              error: sitesResponse.error,
              info: !sitesResponse.result
                ? "Sites Response No Result Obj Returned"
                : "Sites Response Error",
            });
            return Promise.resolve();
          }

          let tagsAggregatedData = normalizeTagConsentResults(
            tagsResponse.result.queryResult.data
          );
          this.aggregatedData["tags"] = tagsAggregatedData;

          let sitesAggregatedData = normalizeTagConsentResults(
            sitesResponse.result.queryResult.data
          );
          this.aggregatedData["sites"] = sitesAggregatedData;

          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message:
              "runConsentMonitoringQuery - TagConsentOverviewWidget - catch",
            error: error,
          });
        });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getConsentErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "demo") {
      this.aggregatedData = TagConsentFixture;
      this.loading = false;
    } else if (
      this.$store.state.containers &&
      this.$store.state.containers.length !== 0
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.tag-consent-overview {
  h2 {
    margin: 10px 0 30px 0;
    width: 100%;
    text-align: left;
  }

  h3 {
    span {
      display: block;
      margin-bottom: 3px;
      font-weight: 500;
      font-size: 13px;
      color: $dark-gray;
      text-transform: uppercase;
    }
    font-size: 20px;
    margin: 0 0 20px 0;

    @include media($x-small-highest, down) {
      text-align: center;
    }
  }

  .tag-consent-overview-wrapper {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;

    > div {
      grid-column: span 2;
      @include media($large, down) {
        grid-column: span 3;
      }
      @include media(1080px, down) {
        grid-column: span 6;
      }
      @include media($small-highest, down) {
        grid-column: span 3;
      }
      @include media(770px, down) {
        grid-column: span 6;
      }
    }
  }

  .tag-consent-pie-section {
    border: 2px solid $light-gray;
    border-radius: 3px;
    padding: 16px;
    > div {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(2, 1fr);
      flex-wrap: wrap;
      width: 100%;
      gap: 16px;
      text-align: center;

      > div {
        margin: 0 auto;
        grid-column: span 1;

        @include media($x-small-highest, down) {
          grid-column: span 2;
        }

        > div {
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}
</style>
