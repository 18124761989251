<template>
  <div
    :class="`tag-consent-monitoring widget ${
      NODE_ENV !== 'demo' ? 'half' : 'third'
    }`"
    v-if="!error"
  >
    <h2 class="title">Tag &amp; Consent Monitoring</h2>
    <div v-if="error || loading">
      <div class="site-error" v-if="error || !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>

    <div class="big-numbers" v-if="!error && !loading">
      <BigNumber :number="sitesManaged" descriptor="Sites Managed" />
      <BigNumber :number="uniqueTags" descriptor="Unique Tags" />
    </div>
    <div class="big-numbers three" v-if="!error && !loading">
      <BigNumber
        :number="globalPrivacyControlTags"
        descriptor="Global Privacy Control"
        subdescriptor="Violating Tags"
      />
      <BigNumber
        :number="AllEnabledTags"
        descriptor="All Enabled"
        subdescriptor="Violating Tags"
      />
      <BigNumber
        :number="NoneEnabledTags"
        descriptor="None Enabled"
        subdescriptor="Violating Tags"
      />
    </div>
    <router-link
      to="/tag-consent/overview"
      class="button"
      v-if="!error && !loading"
    >
      View Details
    </router-link>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { normalizeTagConsentResults } from "@/Helpers.js";
import MetadataService from "@/services/MetadataService";
import { getConsentErrorMessage } from "@/ErrorMessaging";
import BigNumber from "@/components/Shared/BigNumber.vue";

export default {
  name: "TagConsentMonitoring",
  components: { BigNumber, LoadingSpinner },
  props: [],
  data() {
    return {
      loading: true,
      error: null,
      sitesManaged: null,
      uniqueTags: null,
      globalPrivacyControlTags: null,
      AllEnabledTags: null,
      NoneEnabledTags: null,
      NODE_ENV: process.env.NODE_ENV,
    };
  },
  methods: {
    queryTags() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - queryTags - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_tag_aggregated__
          `,
      });
    },
    querySites() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - querySites - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_site_aggregated__
          `,
      });
    },
    getData() {
      this.loading = true; // Ensure loading is true at the beginning

      Promise.all([this.queryTags(), this.querySites()])
        .then((responses) => {
          const tagsResponse = responses[0]; // tags
          const sitesResponse = responses[1]; // sites

          if (!tagsResponse || !sitesResponse) {
            this.triggerError({
              message: "runConsentMonitoringQuery - TagConsentDashWidget",
              info: "Tags Response or Sites Response is undefined",
            });
            return Promise.resolve();
          }

          if (tagsResponse.error || !tagsResponse.result) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentDashWidget - error obj returned from api",
              error: tagsResponse.error,
              info: !tagsResponse.result
                ? "Tags Response No Result Obj Returned"
                : "Tags Response Error",
            });
            return Promise.resolve();
          }

          if (sitesResponse.error || !sitesResponse.result) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentDashWidget - error obj returned from api",
              error: sitesResponse.error,
              info: !sitesResponse.result
                ? "Sites Response No Result Obj Returned"
                : "Sites Response Error",
            });
            return Promise.resolve();
          }

          let tagsAggregatedData = normalizeTagConsentResults(
            tagsResponse.result.queryResult.data
          );

          // get total unique tags
          let uniqueTags = 0;
          for (const [key] of Object.entries(tagsAggregatedData)) {
            if (uniqueTags < tagsAggregatedData[key].total) {
              uniqueTags = tagsAggregatedData[key].total;
            }
          }
          this.uniqueTags = uniqueTags;

          // globalPrivacyControlTags
          this.globalPrivacyControlTags =
            tagsAggregatedData["Global Privacy Control"].violating;
          this.AllEnabledTags = tagsAggregatedData["All Enabled"].violating;
          this.NoneEnabledTags = tagsAggregatedData["None Enabled"].violating;

          let sitesAggregatedData = normalizeTagConsentResults(
            sitesResponse.result.queryResult.data
          );

          // get total sites managed
          let sitesManaged = 0;
          for (const [key] of Object.entries(sitesAggregatedData)) {
            if (sitesManaged < sitesAggregatedData[key].total) {
              sitesManaged = sitesAggregatedData[key].total;
            }
          }
          this.sitesManaged = sitesManaged;

          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message: "runConsentMonitoringQuery - TagConsentDashWidget - catch",
            error: error,
          });
        });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getConsentErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  watch: {
    "$store.state.containers": function () {
      if (
        this.$store.state.containers &&
        this.$store.state.containers.length !== 0
      ) {
        this.getData();
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "demo") {
      (this.sitesManaged = `${277}`),
        (this.uniqueTags = `${12}`),
        (this.notApproved = `${5}`),
        (this.consentViolations = `${2}`),
        (this.loading = false);
    } else if (
      this.$store.state.containers &&
      this.$store.state.containers.length !== 0
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.tag-consent-monitoring {
  h2 {
    margin-top: 0;
    margin-bottom: 30px;
    text-align: left;
    width: 100%;
  }

  .big-numbers {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;
    &.three {
      grid-template-columns: repeat(3, 1fr);
      @include media($large, down) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .button {
    margin: 20px 0 0 auto;
    display: block;
    width: 135px;
    text-align: center;
  }
}
</style>
