<template>
  <div class="loading-spinner"></div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.loading-spinner {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.loading-spinner:after {
  content: " ";
  display: block;
  width: 26px;
  height: 26px;
  margin: 10px;
  border-radius: 50%;
  border: 4px solid $dark-gray;
  border-color: $dark-gray transparent $dark-gray transparent;
  animation: loading-spinner 1.2s linear infinite;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
