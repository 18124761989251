<template>
  <div class="view-container platform-governance-details">
    <section class="view-header">
      <div class="view-title">
        Platform Governance &amp; Standards - Site Details
      </div>
      <div class="view-sublinks" v-if="!error && !loading">
        <LinkWithIcon
          icon="bar-plus-line-graph-data-up"
          text="Overview"
          link="/platform-governance/overview"
        />
        <LinkWithIcon
          icon="list-ui"
          text="Details"
          :link="`/platform-governance/details?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
          v-bind:active="true"
        />
      </div>
    </section>
    <div class="workarea">
      <div class="widget full">
        <div v-if="error || loading">
          <h2 class="title" :style="{ textAlign: 'left', paddingTop: '17px' }">
            Digital Property Score &amp; Risk Factors
          </h2>
          <div class="site-error" v-if="error || !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>
        <div class="table-wrapper" v-if="!error && !loading">
          <div class="table-header">
            <h2 class="title">Digital Property Score &amp; Risk Factors</h2>
            <div class="filters">
              <a
                href=""
                class="button filter-button"
                v-if="NODE_ENV === 'demo'"
              >
                <DisplaySvg name="filter" class="filter-icon" />
                Filter
              </a>
              <NInput v-model:value="searchQuery" placeholder="Search">
                <template #suffix>
                  <DisplaySvg name="search" class="search-icon" />
                </template>
              </NInput>
            </div>
          </div>
          <NDataTable
            :columns="tableColumns"
            :data="filteredData"
            :bordered="false"
            :pagination="pagination"
            class="full platform-governance-details-table"
          ></NDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import AdtechService from "@/services/AdtechService";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import ProgressBar from "@/components/Shared/ProgressBar.vue";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getDashboardErrorMessage } from "@/ErrorMessaging";
import { NDataTable, NInput } from "naive-ui";
import { h } from "vue";

// fixtures
import containersFixture from "@/fixtures/containers.json";
import containersRuleCategoriesFixture from "@/fixtures/containerRuleCategories.json";
import ruleOverviewFixture from "@/fixtures/ruleOverview.json";

export default {
  name: "PlatformGovernanceView",
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: 10,
        disabled: this.filteredData.length <= 10 ? true : false,
      };
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      searchQuery: "",
      authCredentials: [],
      tableColumns: [],
      tableData: [],
      NODE_ENV: process.env.NODE_ENV,
    };
  },
  components: {
    LinkWithIcon,
    DisplaySvg,
    NDataTable,
    NInput,
    LoadingSpinner,
  },
  methods: {
    getTableColumns(data) {
      const getScoreBar = (score, pass_count, denominator) => {
        return h(
          "div",
          {
            class: "score-bar",
          },
          [
            h(ProgressBar, {
              percent: score,
              animate: false,
            }),
            h("p", {}, `${pass_count}/${denominator}`),
          ]
        );
      };

      const tableColumns = [
        {
          title: "ID",
          render(row) {
            return `${row.id.slice(0, 6)}...`;
          },
        },
      ];

      // only display the site name column if at least one row
      // has a site name defined
      let isSiteNameDefined = false;
      for (let i = 0; i < data.length; i++) {
        if (data[i].site) {
          isSiteNameDefined = true;
          break;
        }
      }

      if (isSiteNameDefined) {
        tableColumns.push({
          title: "Site",
          key: "site",
          render(row) {
            return h(
              "p",
              { style: { textAlign: "left", margin: "0" } },
              row.site
            );
          },
        });
      }

      tableColumns.push({
        title: "Total",
        render(row) {
          return getScoreBar(
            row.total,
            row.total_pass_count,
            row.total_denominator
          );
        },
      });

      for (const [key] of Object.entries(data[0].scores)) {
        tableColumns.push({
          title: key,
          render(row) {
            if (row.scores[key]) {
              return getScoreBar(
                row.scores[key].score,
                row.scores[key].pass_count,
                row.scores[key].denominator
              );
            }
            return h("p", { style: { textAlign: "center", margin: "0" } }, "-");
          },
        });
      }

      const orgId = this.$store.state.organization;

      tableColumns.push({
        title: "",
        key: "action",
        render(row) {
          return h(
            EllipsisDropdown,
            {
              options: [
                {
                  key: row.id,
                  link: `/platform-governance/details/${
                    row.id
                  }?org=${encodeURIComponent(orgId)}`,
                  text: "View Details",
                },
              ],
            },
            []
          );
        },
      });

      this.tableColumns = tableColumns;
    },
    getScanContainers() {
      this.loading = true;

      if (
        this.$store.state.scans &&
        this.$store.state.scans[0] &&
        process.env.NODE_ENV !== "demo"
      ) {
        const scanContainersPromise = AdtechService.call(
          "reports.scanContainers",
          {
            scanid: this.$store.state.scans[0].scanid,
          }
        );

        const scanContainerRuleCategoriesPromise =
          this.$store.state.scans && this.$store.state.scans[0]
            ? AdtechService.call("reports.scanContainerRuleCategories", {
                scanid: this.$store.state.scans[0].scanid,
              })
            : null;

        Promise.all([scanContainersPromise, scanContainerRuleCategoriesPromise])
          .then((responses) => {
            const [
              scanContainersResponse,
              scanContainerRuleCategoriesResponse,
            ] = responses;

            if (scanContainersResponse.error) {
              this.triggerError({
                message: "reports.scanContainers - error obj returned from api",
                error: scanContainersResponse.error,
              });
              return Promise.resolve();
            }

            if (scanContainerRuleCategoriesResponse.error) {
              this.triggerError({
                message:
                  "reports.scanContainerRuleCategories - error obj returned from api",
                error: scanContainerRuleCategoriesResponse.error,
              });
              return Promise.resolve();
            }

            const transformedData = this.transformData(
              scanContainersResponse.result.scanContainers,
              scanContainerRuleCategoriesResponse.result.containerRuleCategories
            );
            this.getTableColumns(transformedData);
            this.tableData = transformedData;
            this.loading = false;
          })
          .catch((error) => {
            this.triggerError({
              message:
                "reports.scanContainers + reports.scanContainerRuleCategories - Promise.all - catch",
              error: error,
            });
          });
      }

      if (process.env.NODE_ENV === "demo") {
        const containersRuleCategories = containersRuleCategoriesFixture.map(
          (item) => {
            let newItem = { ...item };
            newItem.rulecategory =
              ruleOverviewFixture[Number(item.rulecategory - 1)].name;
            return newItem;
          }
        );

        const transformedData = this.transformData(
          containersFixture,
          containersRuleCategories
        );

        this.getTableColumns(transformedData);
        this.tableData = transformedData;
        this.loading = false;
      }
    },
    transformData(scanContainersData, scanContainerRuleCategoriesData) {
      let transformedData = [];

      scanContainersData.forEach((container) => {
        let containerRules = scanContainerRuleCategoriesData.filter(
          (rule) => rule.containerid === container.containerid
        );

        let newData = {
          id: container.containerid,
          site: this.$store.getters.getContainerMeta(
            container.containerid,
            "name"
          ),
          total: Math.floor(
            (container.pass_count /
              (container.pass_count + container.fail_count)) *
              100
          ),
          total_pass_count: container.pass_count,
          total_denominator: container.pass_count + container.fail_count,
          scores: {},
        };

        containerRules.forEach((rule) => {
          let score = Math.floor(
            (rule.pass_count / (rule.pass_count + rule.fail_count)) * 100
          );
          score = isNaN(score) ? 0 : score; // Handle division by zero

          newData.scores[rule.rulecategory] = {
            score: score,
            pass_count: rule.pass_count,
            denominator: rule.pass_count + rule.fail_count,
          };
        });

        transformedData.push(newData);
      });

      return transformedData;
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getDashboardErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  watch: {
    "$store.state.scans": function (newScans) {
      this.$nextTick(async () => {
        if (newScans && newScans.error) {
          this.loading = false;
          this.error = newScans.error;
        }

        if (
          newScans &&
          newScans.length > 0 &&
          this.$store.state.containers &&
          !this.$store.state.containers.error
        ) {
          this.getScanContainers();
        }
      });
    },
    "$store.state.containers": function (newContainers) {
      this.$nextTick(async () => {
        if (newContainers && newContainers.error) {
          this.loading = false;
          this.error = newContainers.error;
        }

        if (
          newContainers &&
          !newContainers.error &&
          this.$store.state.scans &&
          this.$store.state.scans.length > 0
        ) {
          this.getScanContainers();
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/platform-governance/overview");
      }
    },
  },
  mounted() {
    if (this.$store.state.scans && this.$store.state.scans.error) {
      this.loading = false;
      this.error = this.$store.state.scans.error;
    }

    if (this.$store.state.scans) {
      this.getScanContainers();
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.platform-governance-details {
  .widget {
    display: block;
  }

  .table-wrapper {
    min-height: 650px;
    min-width: 875px;
  }

  .search-icon {
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
    color: $dark-gray;
  }

  .filter-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  .filter-button {
    margin-right: 20px;
  }
}
</style>
