<template>
  <div class="view-container site-detail">
    <div class="alert warning" v-if="this.loadingRow || this.loadingRow === 0">
      <div class="site-loading"><LoadingSpinner /> Loading Row Data...</div>
    </div>
    <div class="alert error" v-if="this.alert">
      {{ this.alert }}
    </div>
    <section class="view-header">
      <div class="half">
        <div class="title-eyebrow">
          <router-link to="/platform-governance/overview" class="eyebrow-link">
            Platform Governance &amp; Standards
          </router-link>
          <DisplaySvg name="caret-right" class="caret" />
          <router-link
            :to="`/platform-governance/details?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
            class="eyebrow-link"
          >
            Site Details
          </router-link>
        </div>
        <div class="view-title-with-btn">
          <div class="view-title" v-if="container != null">
            {{ container.name }}
          </div>
          <router-link to="" class="button share" v-if="NODE_ENV === 'demo'">
            <DisplaySvg name="share" class="share-icon" />Share
          </router-link>
        </div>
      </div>
      <div class="half"></div>
    </section>
    <div class="widget" v-if="error || loading">
      <div class="site-error" v-if="error || !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div class="workarea" v-if="!error && !loading">
      <div class="widget full site-detail-wrapper">
        <h2 class="title">Overview</h2>
        <div>
          <div class="totalScore">
            <DoughnutChartScore :score="totalScore(0)" size="medium" />
            <div>
              <h3>Total Score</h3>
              <Delta
                :past_count="totalScore(1)"
                :current_count="totalScore(0)"
              />
            </div>
          </div>
          <div class="violations">
            <div class="number">{{ totalViolations(0) }}</div>
            <div>
              <h3>Total Violations</h3>
              <Delta
                :past_count="totalViolations(1)"
                :current_count="totalViolations(0)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="widget full"
        v-for="(categoryRules, index) in groupedByCategory[0]"
        :key="index"
      >
        <div class="table-wrapper">
          <div class="table-header">
            <h2 class="title">{{ index }}</h2>
            <DoughnutChartScore
              :score="calculateScore(categoryRules)"
              size="small"
            />
          </div>
          <NDataTable
            :columns="tableColumns"
            :data="categoryRules"
            :bordered="false"
            class="full site-detail-table"
          ></NDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { reactive, h } from "vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import ProgressBar from "@/components/Shared/ProgressBar.vue";
import DoughnutChartScore from "@/components/Shared/DoughnutChartScore.vue";
import Delta from "@/components/Shared/Delta.vue";
import AdtechService from "@/services/AdtechService.js";
import MetadataService from "@/services/MetadataService.js";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getDetailErrorMessage } from "@/ErrorMessaging";
import { NDataTable } from "naive-ui";
import { ref } from "vue";

import ruleOverviewFixture from "@/fixtures/ruleOverview.json";
import siteDetailFixture from "@/fixtures/siteDetails.json";

//
const isExpandedTableOpen = ref({});

export default {
  name: "SiteDetailView",
  components: {
    DisplaySvg,
    NDataTable,
    DoughnutChartScore,
    Delta,
    LoadingSpinner,
  },
  props: {},
  watch: {
    "$store.state.scans": function (newScans) {
      this.$nextTick(() => {
        if (
          (newScans && newScans.error) ||
          !Object.prototype.hasOwnProperty.call(newScans, 0) ||
          !Object.prototype.hasOwnProperty.call(newScans, 1)
        ) {
          this.loading = false;
          this.error = getDetailErrorMessage(
            this.$store.state.organization,
            `site ${this.$route.params.id}`
          );
        }

        if (
          newScans &&
          newScans.length > 0 &&
          JSON.stringify(this.groupedByCategory[0]) === "{}" &&
          JSON.stringify(this.groupedByCategory[1]) === "{}"
        ) {
          this.getRuleResults(0, () => {
            this.getRuleResults(1);
          });
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/platform-governance/overview");
      }
    },
  },
  data() {
    const getScoreBar = (score, denominator) => {
      return h(
        "div",
        {
          class: "score-bar",
        },
        [
          h(ProgressBar, {
            percent: Math.round((score / denominator) * 100),
            animate: false,
          }),
          h("p", {}, `${score} / ${denominator}`),
        ]
      );
    };

    const getFlag = (value, scoring) => {
      let flag = false;
      if (
        (scoring === "Yes/No" && value === "No") ||
        (scoring === "0 - 100%" && value < 80)
      ) {
        flag = true;
      }

      if (
        (scoring !== "Yes/No" && scoring !== "0 - 100%") ||
        value === "Skipped"
      ) {
        return null;
      }

      return h(
        "div",
        {
          class: "flag",
        },
        [
          h(DisplaySvg, {
            name: `${flag ? "failure-flag" : "success-flag"}`,
            class: `${flag ? "failure-flag" : "success-flag"}`,
            style: {
              position: "relative",
              top: "4px",
            },
          }),
        ]
      );
    };

    const getLink = (link) => {
      const linkIcon = h(
        "a",
        {
          href: link,
        },
        [
          h(DisplaySvg, {
            name: "link",
            class: "link-icon",
          }),
        ]
      );

      return h(
        "div",
        {
          class: "fix-link",
        },
        [linkIcon]
      );
    };

    const getSubTable = (index, expandedData, row) => {
      const truncateString = (str, maxLength) => {
        if (str && str.length > maxLength) {
          return str.slice(0, maxLength - 3) + "...";
        }
        return str;
      };

      if (expandedData == null) return "";

      const pagination = {
        pageSize: 5,
        disabled: expandedData.length <= 5 ? true : false,
      };

      const orgId = this.$store.state.organization;

      const subTableColumns = [
        {
          title: "Rule Violated",
          render(row) {
            return h(
              "p",
              {
                style: {
                  padding: "0",
                  margin: "0",
                },
              },
              [
                truncateString(
                  row.details && row.details.displayName
                    ? row.details.displayName
                    : row.fixtip,
                  40
                ),
              ]
            );
          },
        },
        {
          title: "Type",
          key: "violationcode",
          width: 160,
          render(row) {
            return h(
              "p",
              {
                style: {
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                },
              },

              [truncateString(row.violationcode, 16)]
            );
          },
        },
        {
          title: "Severity",
          key: "severity",
          minWidth: 140,
          maxWidth: 140,
          width: 140,
          render(row) {
            if (row.severity == "0") {
              return h("div", { class: "info" }, "Info");
            } else if (row.severity == "1") {
              return h("div", { class: "low" }, "Low");
            } else if (row.severity == "2") {
              return h("div", { class: "medium" }, "Medium");
            } else if (row.severity == "3") {
              return h("div", { class: "high" }, "High");
            } else if (row.severity == "4") {
              return h("div", { class: "severe" }, "Severe");
            } else if (row.severity == "5") {
              return h("div", { class: "critical" }, "Critical");
            }
          },
        },
        {
          title: "",
          key: "action",
          width: 160,
          render(row) {
            const id = row.ruleinstanceid;
            const containerid = row.containerid;
            const violationnum = row.violationnum;
            const entityid = row.entityid;
            const scanid = row.scanid;

            return h(
              EllipsisDropdown,
              {
                options: [
                  {
                    key: row.ruleinstanceid,
                    link: `/platform-governance/violations/${id}?containerid=${containerid}&violationnum=${violationnum}&entityid=${entityid}&scanid=${scanid}&org=${encodeURIComponent(
                      orgId
                    )}`,
                    text: "View Details",
                  },
                ],
                style: {
                  position: "relative",
                  top: "8px",
                },
              },
              []
            );
          },
        },
      ];

      const isRowExpanded = isExpandedRowOpen(index, row);

      return h(
        "div",
        {
          style: {
            top: "51px",
            position: "absolute",
            left: 0,
            width: "100%",
            display: isRowExpanded ? "block" : "none",
            opacity: isRowExpanded ? "1" : 0,
            transition: "opacity 0.3s",
          },
        },
        [
          h(NDataTable, {
            columns: subTableColumns,
            data: expandedData,
            pagination: pagination,
            bordered: false,
            class: "site-detail-sub-table",
          }),
        ]
      );
    };

    const expandTable = (value, row) => {
      console.log(isExpandedTableOpen.value);
      if (this.loadingRow) {
        this.alert =
          "Please wait for your previous query to finish loading before opening another.";
        setTimeout(() => {
          this.alert = null;
        }, 3000);
      } else {
        let obj = isExpandedTableOpen.value;
        let array = obj[`${row.category}`] ? obj[`${row.category}`] : [];
        const findItem = array.map((e) => e.id).indexOf(value);
        if (findItem >= 0) {
          array.splice(findItem, 1);
          obj[`${row.category}`] = array;
          isExpandedTableOpen.value = obj;
          return;
        }

        this.loadingRow = `${row.category}-${value}`;
        let expandedData = null;

        if (process.env.NODE_ENV === "demo") {
          expandedData = siteDetailFixture.violationsArray;
          array.push({ id: value, expandedData: expandedData });
          obj[`${row.category}`] = array;
          isExpandedTableOpen.value = obj;
          this.loadingRow = false;
          return;
        }

        AdtechService.call("ruleviolations.list", {
          orgid: this.$store.state.organization,
          scanid: this.$store.state.scans[0].scanid,
          filter: {
            containerid: row.scanResults.containerid,
            ruleinstanceid: row.ruleinstanceid,
          },
        })
          .then((response) => {
            if (response.error) {
              console.log("%cError", "color: white; background-color: red", {
                message: "ruleviolations.list - error obj returned from api",
                error: response.error,
              });
              this.alert = "Oops. This row could not be loaded.";
              setTimeout(() => {
                this.alert = null;
              }, 3000);
              this.loadingRow = false;
              return Promise.resolve();
            }
            expandedData = response.result.violations;
            array.push({ id: value, expandedData: expandedData });

            obj[`${row.category}`] = array;
            isExpandedTableOpen.value = obj;
            this.loadingRow = false;
          })
          .catch((error) => {
            console.log("%cError", "color: white; background-color: red", {
              message: "ruleviolations.list - catch",
              error: error,
            });

            this.alert = "Oops. This row could not be loaded.";
            setTimeout(() => {
              this.alert = null;
            }, 3000);
            this.loadingRow = false;
          });
      }
    };

    const getLoadingRow = (index) => {
      return this.loadingRow === index;
    };

    const isExpandedRowOpen = (value, row) => {
      let obj = isExpandedTableOpen.value;
      let array = obj[`${row.category}`] ? obj[`${row.category}`] : [];

      const findItem = array.map((e) => e.id).indexOf(value);

      if (findItem >= 0) {
        return isExpandedTableOpen.value[`${row.category}`][findItem];
      } else {
        return false;
      }
    };

    let tableColumns = [
      {
        title: "Rule",
        key: "name",
      },
    ];

    if (process.env.NODE_ENV === "demo") {
      tableColumns.push({
        title: "Value",
        width: 150,
        render(row) {
          if (row.scoring === "0 - 100%") {
            return getScoreBar(row.exampleValue, 100);
          } else if (row.scoring === "Link") {
            return getLink(row.exampleValue, false);
          }

          return h(
            "p",
            {
              style: {
                textAlign: "center",
                padding: "0",
                margin: "0",
              },
            },
            [row.exampleValue]
          );
        },
      });
    }

    tableColumns.push({
      title: "Flag",
      key: "flag",
      width: 100,
      render(row) {
        if (process.env.NODE_ENV === "demo") {
          return getFlag(row.exampleValue, row.scoring);
        }
        // row.scanResults.status - possible values: FAIL, SKIPPED, PASS
        if (row.scanResults) {
          return getFlag(
            row.scanResults.status === "PASS"
              ? "Yes"
              : row.scanResults.status === "FAIL"
              ? "No"
              : "Skipped",
            "Yes/No"
          );
        }

        return h(
          "p",
          {
            style: {
              textAlign: "center",
              padding: "0",
              margin: "0",
            },
          },
          "-"
        );
      },
    });

    tableColumns.push({
      title: "Violation Count",
      width: 200,
      render(row) {
        return h(
          "p",
          {
            style: {
              textAlign: "center",
              padding: "0",
              margin: "0",
            },
          },
          [
            process.env.NODE_ENV === "demo"
              ? 2
              : row.scanResults
              ? row.scanResults.violations
              : "",
          ]
        );
      },
    });

    tableColumns.push({
      title: "Violations",
      width: 160,
      render(row, index) {
        const isRowExpanded = isExpandedRowOpen(index, row);
        const subTable = getSubTable(index, isRowExpanded.expandedData, row);
        const arrayLength =
          process.env.NODE_ENV === "demo"
            ? siteDetailFixture.violations
            : row.scanResults
            ? row.scanResults.violations
            : 0;
        const offset = arrayLength > 5 ? 94 : 130;
        const loadingRow = getLoadingRow(index);

        const height =
          arrayLength > 5 ? 5 * 71 + offset : arrayLength * 60 + offset;

        const buttonStyles = {
          width: "88px",
          display: "flex",
          color: isRowExpanded ? "#fff" : "#143755",
          backgroundColor: isRowExpanded ? "#143755" : "#fff",
        };

        return h(
          "div",
          {
            class: "expand-col",
            style: {
              paddingBottom: `${isRowExpanded ? height : 0}px`,
              transition: "padding 0.3s",
            },
          },
          [
            arrayLength > 0
              ? h(
                  "button",
                  {
                    class: "button-tiny",
                    style: buttonStyles,
                    disabled: loadingRow >= 0 && loadingRow !== false,
                    onClick: () => {
                      expandTable(index, row);
                    },
                  },
                  [
                    "expand",
                    h(DisplaySvg, {
                      name: isRowExpanded ? "x" : "expand",
                      class: "expand-icon",
                      style: {
                        color: !isRowExpanded ? "#143755" : "#ffffff",
                        paddingTop: isRowExpanded ? "2px" : "0",
                      },
                    }),
                  ]
                )
              : h(
                  "p",
                  {
                    style: {
                      textAlign: "center",
                      padding: "0",
                      margin: "0",
                    },
                  },
                  ["--"]
                ),
            subTable,
          ]
        );
      },
    });

    return {
      id: this.$route.params.id,
      container: null,
      NODE_ENV: process.env.NODE_ENV,
      groupedByCategory: {
        0: reactive({}),
        1: reactive({}),
      },
      tableColumns: tableColumns,
      error: null,
      loading: true,
      loadingRow: false,
      alert: null,
    };
  },
  methods: {
    getRuleResults(scan, nextCall) {
      if (process.env.NODE_ENV === "demo") {
        let ruleResults = {};

        ruleOverviewFixture.map((rule) => {
          ruleResults[rule.name] = rule.data;
        });

        this.groupedByCategory[scan] = ruleResults;
        this.loading = false;

        return;
      }

      let ruleResultsCall =
        this.$store.state.scans && this.$store.state.scans[scan]
          ? AdtechService.call("ruleresults.list", {
              scanid: this.$store.state.scans[scan].scanid,
              orgid: this.$store.state.organization,
              filter: {
                containerid: this.$route.params.id,
              },
            })
          : null;

      let rulesCall = AdtechService.call("rules.listAccessibleRules", {
        orgid: this.$store.state.organization,
      });

      Promise.all([ruleResultsCall, rulesCall])
        .then(([ruleResultsResponse, rulesResponse]) => {
          if (ruleResultsResponse.error) {
            console.log("%cError", "color: white; background-color: red", {
              message: "ruleresults.list - error obj returned from api",
              error: ruleResultsResponse.error,
            });
            this.error = getDetailErrorMessage(
              this.$store.state.organization,
              `site ${this.$route.params.id}`
            );
            this.loading = false;
            return Promise.resolve();
          }

          if (rulesResponse.error) {
            console.log("%cError", "color: white; background-color: red", {
              message:
                "rules.listAccessibleRules - error obj returned from api",
              error: rulesResponse.error,
            });
            this.error = getDetailErrorMessage(
              this.$store.state.organization,
              `site ${this.$route.params.id}`
            );
            this.loading = false;
            return Promise.resolve();
          }

          // Create a mapping of rule instances
          let rulesMap = {};

          rulesResponse.result.rules.forEach((rule) => {
            rulesMap[rule.ruleinstanceid] = rule;
          });

          // Augment rule data with scan results
          ruleResultsResponse.result.ruleresults.forEach((scanResult) => {
            if (
              Object.prototype.hasOwnProperty.call(
                rulesMap,
                scanResult.ruleinstanceid
              )
            ) {
              if (!rulesMap[scanResult.ruleinstanceid]) {
                return;
              }
              if (!rulesMap[scanResult.ruleinstanceid].scanResults) {
                rulesMap[scanResult.ruleinstanceid].scanResults = {
                  containerid: scanResult.containerid,
                  ruleinstanceid: scanResult.ruleinstanceid,
                  status: 'PASS',
                  violations: 0
                };
              }
              let scanResultAgg = rulesMap[scanResult.ruleinstanceid].scanResults;
              // For now, set the aggregated status to the worst status of any scanResult
              if (scanResult.status === 'PASS') {
                // Do nothing
              } else if (scanResult.status === 'FAIL') {
                scanResultAgg.status = 'FAIL';
              } else if (scanResultAgg.status !== 'FAIL') {
                scanResultAgg.status = scanResult.status;
              }
              scanResultAgg.violations += scanResult.violations;
            }
          });

          // Group by categories
          Object.values(rulesMap).forEach((rule) => {
            rule.categories.forEach((category) => {
              if (
                !Object.prototype.hasOwnProperty.call(
                  this.groupedByCategory[scan],
                  category
                )
              ) {
                this.groupedByCategory[scan][category] = [];
              }
              this.groupedByCategory[scan][category].push({
                ...rule,
                category: category,
              });
            });
          });

          if (nextCall) {
            nextCall();
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log("%cError", "color: white; background-color: red", {
            message:
              "ruleresults.list + rules.listAccessibleRules - Promise.all - catch",
            error: error,
          });

          this.error = getDetailErrorMessage(
            this.$store.state.organization,
            `site ${this.$route.params.id}`
          );
          this.loading = false;
        });
    },
    getContainer() {
      if (process.env.NODE_ENV === "demo") {
        this.container = { name: "example.com" };
        return;
      }

      MetadataService.call("analyticscontainers.get", {
        containerid: this.$route.params.id,
      })
        .then((response) => {
          if (response.error) {
            console.log("%cError", "color: white; background-color: red", {
              message: "analyticscontainers.get - error obj returned from api",
              error: response.error,
            });
            this.error = getDetailErrorMessage(
              this.$store.state.organization,
              `site ${this.$route.params.id}`
            );
            this.loading = false;
            return Promise.resolve();
          }

          this.container = response.result.container;
        })
        .catch((error) => {
          console.log("%cError", "color: white; background-color: red", {
            message: "analyticscontainers.get - catch",
            error: error,
          });
          this.error = getDetailErrorMessage(
            this.$store.state.organization,
            `site ${this.$route.params.id}`
          );
          this.loading = false;
        });
    },
    calculateScore(categoryData) {
      if (process.env.NODE_ENV === "demo") {
        let demoFailed = 0;
        let totalItems = 0;

        categoryData.forEach((category) => {
          if (
            (category.scoring === "Yes/No" && category.exampleValue === "No") ||
            (category.scoring === "0 - 100%" && category.exampleValue < 80)
          ) {
            demoFailed++;
            totalItems++;
          }

          if (
            (category.scoring === "Yes/No" &&
              category.exampleValue === "Yes") ||
            (category.scoring === "0 - 100%" && category.exampleValue >= 80)
          ) {
            totalItems++;
          }
        });

        return Math.floor(((totalItems - demoFailed) / totalItems) * 100);
      }

      let failed = 0;
      categoryData.forEach((category) => {
        if (category.scanResults && category.scanResults.status == "FAIL") {
          failed++;
        }
      });
      return Math.floor(
        ((categoryData.length - failed) / categoryData.length) * 100
      );
    },
    totalScore(scan) {
      if (process.env.NODE_ENV === "demo") {
        return scan === 0
          ? siteDetailFixture.totalScore
          : siteDetailFixture.pastTotalScore;
      }

      let failed = 0;
      let rules = 0;
      Object.keys(this.groupedByCategory[scan]).forEach((key) => {
        this.groupedByCategory[scan][key].forEach((categoryRule) => {
          rules++;
          if (
            Object.prototype.hasOwnProperty.call(categoryRule, "scanResults") &&
            categoryRule.scanResults.status == "FAIL"
          ) {
            failed++;
          }
        });
      });
      return Math.floor(((rules - failed) / rules) * 100);
    },
    totalViolations(scan) {
      if (process.env.NODE_ENV === "demo") {
        return scan === 0
          ? siteDetailFixture.totalViolations
          : siteDetailFixture.pastTotalViolations;
      }

      let failed = 0;
      Object.keys(this.groupedByCategory[scan]).forEach((key) => {
        this.groupedByCategory[scan][key].forEach((categoryRule) => {
          if (
            Object.prototype.hasOwnProperty.call(categoryRule, "scanResults") &&
            categoryRule.scanResults.status == "FAIL"
          ) {
            failed++;
          }
        });
      });
      return failed;
    },
    resetOpenSubTables() {
      isExpandedTableOpen.value = {};
    },
  },
  mounted() {
    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }

    if (this.$store.state.scans && this.$store.state.scans.length > 0) {
      this.getRuleResults(0, () => {
        this.getRuleResults(1);
      });
    }

    this.getContainer();
  },
  beforeUnmount() {
    // clear open table on unmount
    this.resetOpenSubTables();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.site-detail {
  .alert {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: $shadow;
    left: 50vw;
    width: 320px;
    top: 150px;
    z-index: 99;
    &.warning {
      border: 2px solid $yellow;
    }
    &.error {
      border: 2px solid $red;
    }
    @include media($small-highest, down) {
      left: calc(50vw - 160px);
    }
  }

  .caret {
    position: relative;
    top: -1px;
  }
  .table-wrapper {
    width: 100%;
    min-width: 800px;
  }

  .table-header {
    align-items: center;
    justify-content: space-between;

    .title {
      min-width: auto;
      margin-right: 40px;
      position: relative;
      top: 4px;
    }
  }

  .widget {
    display: block;
  }
}

.site-detail-wrapper {
  > div {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;
  }

  h2 {
    margin-top: 0;
    text-align: left;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  .totalScore,
  .violations {
    display: flex;
    align-items: center;
    padding: 10px 0;
    @include media($large-highest, down) {
      justify-content: center;
    }
    @include media($x-small, down) {
      flex-wrap: wrap;
    }
  }

  .violations {
    @include media($x-small, down) {
      > div {
        &:last-child {
          width: 100%;
          text-align: center;
          margin-top: 16px;
        }
      }
    }
  }

  .totalScore {
    > div {
      &:last-child {
        margin-left: 16px;
        @include media($x-small, down) {
          margin-left: 0;
          width: 100%;
          text-align: center;
          margin-top: 16px;
        }
      }
    }
  }

  .table-1,
  .table-2 {
    grid-column: span 1;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    max-width: 380px;
    margin-top: 12px;
    @include media($medium, down) {
      padding: 0;
      margin-top: 0px;
    }
  }

  .totalScore,
  .violations,
  .table-1,
  .table-2 {
    grid-column: span 1;
    @include media($large-highest, down) {
      grid-column: span 2;
    }

    @include media($medium, down) {
      grid-column: span 4;
    }

    @include media($small-highest, down) {
      grid-column: span 2;
    }

    @include media($small, down) {
      grid-column: span 4;
    }
  }
  .number {
    font-size: 38px;
    line-height: 38px;
    font-weight: 700;
    padding: 16px;
    color: $navy;
    background-color: $light-gray;
    border-radius: $border-radius-1;
    margin-right: 16px;
    @include media($x-small, down) {
      margin-right: 0;
    }
  }
}
</style>
