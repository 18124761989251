import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import LoginView from "../views/LoginView.vue";
import LoginFailureView from "../views/LoginFailureView.vue";
import TagConsentView from "../views/TagConsentView.vue";
import TagConsentDetailsView from "../views/TagConsent/TagConsentDetailsView.vue";
import TagConsentTagDetailView from "../views/TagConsent/TagConsentTagDetailView.vue";
import TagConsentSiteDetailView from "../views/TagConsent/TagConsentSiteDetailView.vue";
import PlatformGovernanceView from "../views/PlatformGovernanceView.vue";
import PlatformGovernanceDetailsView from "../views/PlatformGovernance/PlatformGovernanceDetailsView.vue";
import MediaPerformanceView from "../views/MediaPerformanceView.vue";
import RuleOverviewView from "../views/PlatformGovernance/RuleOverviewView.vue";
import ViolationsView from "../views/PlatformGovernance/ViolationsView.vue";
import SiteDetailView from "../views/PlatformGovernance/SiteDetailView.vue";
import ViolationsDetailView from "../views/PlatformGovernance/ViolationDetailView.vue";
import ManageAccountProfile from "../views/ManageAccount/ManageAccountProfile.vue";
import ManageAccountUsers from "../views/ManageAccount/ManageAccountUsers.vue";
import ManageAccountCredentials from "../views/ManageAccount/ManageAccountCredentials.vue";
import WhatsNew from "../views/WhatsNew.vue";
import store from "@/store/index.js";

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresLogin: true },
  },
  {
    path: "/",
    alias: ["/login", "/index.html"],
    name: "login",
    component: LoginView,
    meta: { requiresLogin: false },
  },
  {
    path: "/",
    alias: ["/login-failure"],
    name: "login-failure",
    component: LoginFailureView,
    meta: { requiresLogin: false },
  },
  {
    path: "/tag-consent/overview",
    name: "tag-consent-overview",
    component: TagConsentView,
    meta: { requiresLogin: true },
  },
  {
    path: "/tag-consent/details",
    name: "tag-consent-details",
    component: TagConsentDetailsView,
    meta: { requiresLogin: true },
  },
  {
    path: "/tag-consent/tag/:id",
    name: "tag-consent-tag-details",
    component: TagConsentTagDetailView,
    meta: { requiresLogin: true },
  },
  {
    path: "/tag-consent/site/:id",
    name: "tag-consent-site-details",
    component: TagConsentSiteDetailView,
    meta: { requiresLogin: true },
  },
  {
    path: "/platform-governance/overview",
    name: "platform-governance-overview",
    component: PlatformGovernanceView,
    meta: { requiresLogin: true },
  },
  {
    path: "/platform-governance/details",
    name: "platform-governance-details",
    component: PlatformGovernanceDetailsView,
    meta: { requiresLogin: true },
  },
  {
    path: "/platform-governance/violations",
    name: "violations",
    component: ViolationsView,
    meta: { requiresLogin: true },
  },
  {
    path: "/platform-governance/example-rule-overview",
    name: "example-rule-overview",
    component: RuleOverviewView,
    meta: { requiresLogin: true },
    props: {
      displayExample: true,
    },
  },
  {
    path: "/platform-governance/rule-overview",
    name: "rule-overview",
    component: RuleOverviewView,
    meta: { requiresLogin: true },
    props: {
      displayExample: false,
    },
  },
  {
    path: "/platform-governance/details/:id",
    name: "site-detail",
    component: SiteDetailView,
    meta: { requiresLogin: true },
  },
  {
    path: "/platform-governance/violations/:id",
    name: "violations-detail",
    component: ViolationsDetailView,
    meta: { requiresLogin: true },
  },
  {
    path: "/media-performance/overview",
    name: "media-performance",
    component: MediaPerformanceView,
    meta: { requiresLogin: true },
  },
  {
    path: "/manage-account/profile",
    name: "manage-account-profile",
    component: ManageAccountProfile,
    meta: { requiresLogin: true },
  },
  {
    path: "/manage-account/user-management",
    name: "manage-account-users",
    component: ManageAccountUsers,
    meta: { requiresLogin: true },
  },
  {
    path: "/manage-account/credential-management",
    name: "manage-account-credentials",
    component: ManageAccountCredentials,
    meta: { requiresLogin: true },
  },
  {
    path: "/manage-account/profile",
    name: "manage-account-profile",
    component: ManageAccountProfile,
    meta: { requiresLogin: true },
  },
  {
    path: "/changelog",
    name: "whats-new",
    component: WhatsNew,
    meta: { requiresLogin: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      mounted() {
        this.$store.commit("logout");
      },
    },
    meta: { requiresLogin: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => {
  const user = store.state.userData; //store with namespaced  modules
  if (to.matched.some((record) => record.meta.requiresLogin) && user == null) {
    if (from.name == "login") return false;
    next({ name: "login" }); //redirect to login
  } else {
    next(); //proceed to the route
  }
});

router.beforeEach((to, from, next) => {
  document.title = "Adtech Dashboard";
  next();
});

export default router;
