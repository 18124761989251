<template>
  <router-link :to="link" v-bind:class="getClasses(right, active, className)">
    <span class="icon-wrapper" v-if="right === false"
      ><DisplaySvg :name="icon"
    /></span>
    {{ text }}
    <span class="icon-wrapper" v-if="right === true"
      ><DisplaySvg :name="icon"
    /></span>
  </router-link>
</template>

<script>
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";

export default {
  name: "LinkWithIcon",
  components: {
    DisplaySvg,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
  },
  methods: {
    getClasses(right, active, className) {
      let classes = {
        "link-with-icon": true,
        "icon-on-left": right === false,
        "icon-on-right": right === true,
        "active-tab": active === true,
      };
      if (className) {
        classes[className] = true;
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.link-with-icon {
  color: $navy;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  margin: 5px 0 0 0;
  transition: $transition color;
  .icon-wrapper {
    width: 12px;
    position: relative;
    top: 1px;
  }
  &.icon-on-right {
    .icon-wrapper {
      margin-left: 5px;
    }
  }
  &.icon-on-left {
    .icon-wrapper {
      margin-right: 5px;
    }
  }

  &.active-tab {
    background-color: $white;
    border-radius: $border-radius-2;
    padding: 10px;
    border: 1px solid $medium-gray;
  }

  @include hover {
    color: $blue;
  }
}
</style>
