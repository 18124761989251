<template>
  <div class="view-container site-detail">
    <section class="view-header">
      <div class="half">
        <div class="title-eyebrow">
          <router-link to="/platform-governance/overview" class="eyebrow-link">
            Platform Governance &amp; Standards
          </router-link>
          <DisplaySvg name="caret-right" class="caret" v-if="displayExample" />
          <router-link
            v-if="displayExample"
            :to="`/platform-governance/rule-overview?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
            class="eyebrow-link"
          >
            {{ this.$store.state.organization }} Rule Overview
          </router-link>
        </div>
        <div class="view-title-with-btn">
          <div class="view-title">
            <span v-if="displayExample">All Configurable Rules</span
            ><span v-if="!displayExample">Rule Overview</span>
          </div>
          <router-link
            :to="`/platform-governance/example-rule-overview?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
            class="button"
            v-if="!displayExample && NODE_ENV != 'demo'"
          >
            View Configurable Rules
          </router-link>
        </div>
        <p>
          <span v-if="!displayExample"
            >All rules currently implemented for the
            {{ this.$store.state.organization }} organization.</span
          >
          <span v-if="displayExample"
            >If you would like to apply any of the below rules for the
            {{ this.$store.state.organization }} organization, please reach out
            to your InfoTrust advisor.</span
          >
        </p>
      </div>
      <div class="half"></div>
    </section>
    <div class="widget" v-if="error || loading">
      <div class="site-error" v-if="error || !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div class="workarea" v-if="!error && !loading">
      <div
        class="widget full"
        v-for="(rule, index) in ruleOverviewData"
        :key="index"
      >
        <div class="table-wrapper">
          <div class="table-header">
            <h2 class="title">{{ index }}</h2>
            <NCollapse accordion arrow-placement="right">
              <NCollapseItem title="Details" :name="index + displayExample">
                <NDataTable
                  :columns="tableColumns"
                  :data="rule"
                  :bordered="false"
                  class="full rule-overview-table"
                ></NDataTable>
                <router-link
                  to=""
                  class="button addRuleBtn"
                  v-if="NODE_ENV === 'demo'"
                >
                  Add Rule
                </router-link>
              </NCollapseItem>
            </NCollapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import AdtechService from "@/services/AdtechService.js";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { getRuleOverviewMessage } from "@/ErrorMessaging";
import { NDataTable, NCollapse, NCollapseItem } from "naive-ui";
import { h, reactive } from "vue";
import ruleOverviewFixture from "@/fixtures/ruleOverview.json";

export default {
  name: "RuleOverview",
  components: {
    NDataTable,
    NCollapse,
    NCollapseItem,
    LoadingSpinner,
    DisplaySvg,
  },
  props: ["displayExample"],
  methods: {
    getAllRuleTempletesForOrg() {
      let rulesByCategory = {};

      AdtechService.call("ruletemplates.listUsableRuleTemplates", {
        orgid: this.$store.state.organization,
      }).then((rulesTemplateListResponse) => {
        if (rulesTemplateListResponse.error) {
          this.triggerError({
            message:
              "ruletemplates.listUsableRuleTemplate - error obj returned from api",
            error: rulesTemplateListResponse.error,
          });
          return Promise.resolve();
        }

        const ruleDataCombined =
          rulesTemplateListResponse.result.ruletemplates.map(
            (ruleTemplateItem) => {
              // scoretype
              // "BINARY" = "Yes/No"
              // "VIOLATION_COUNT" = "Count"
              // "VIOLATION_FRACTION" = "0 - 100%"
              let scoring = "--";
              if (ruleTemplateItem.scoretype == "BINARY") {
                scoring = "Yes/No";
              } else if (ruleTemplateItem.scoretype == "VIOLATION_COUNT") {
                scoring = "Count";
              } else if (ruleTemplateItem.scoretype == "VIOLATION_FRACTION") {
                scoring = "0 - 100%";
              }

              return {
                description: ruleTemplateItem.description,
                categories: ruleTemplateItem.categories,
                name: ruleTemplateItem.name,
                ruletemplateid: ruleTemplateItem.ruletemplateid,
                scoring: scoring,
                source:
                  ruleTemplateItem.dataresources &&
                  ruleTemplateItem.dataresources.length > 0
                    ? ruleTemplateItem.dataresources[0].split(".")[0]
                    : "",
              };
            }
          );

        ruleDataCombined.map((rule) => {
          rule.categories.map((cat) => {
            if (!rulesByCategory[cat]) {
              rulesByCategory[cat] = [];
            }

            rulesByCategory[cat].push(rule);
          });
        });

        this.ruleOverviewData = rulesByCategory;

        if (JSON.stringify(rulesByCategory) === "{}") {
          this.error = getRuleOverviewMessage(this.$store.state.organization);
        }

        this.tableColumns = this.setTableColumns();

        this.loading = false;
      });
    },
    getAllActiveRulesForOrg() {
      let rulesByCategory = {};

      const ruleList = AdtechService.call("rules.list", {
        limit: 1000,
        orgid: this.$store.state.organization,
      });

      const rulesTemplateList = AdtechService.call(
        "ruletemplates.listUsableRuleTemplates",
        {
          orgid: this.$store.state.organization,
        }
      );

      return Promise.all([ruleList, rulesTemplateList])
        .then(([ruleListResponse, rulesTemplateListResponse]) => {
          if (ruleListResponse.error) {
            this.triggerError({
              message: "rules.list - error obj returned from api",
              error: ruleListResponse.error,
            });
            return Promise.resolve();
          }

          if (rulesTemplateListResponse.error) {
            this.triggerError({
              message:
                "ruletemplates.listUsableRuleTemplates - error obj returned from api",
              error: rulesTemplateListResponse.error,
            });
            return Promise.resolve();
          }

          const ruleDataCombined = ruleListResponse.result.rules.map(
            (ruleItem) => {
              const ruleTemplateItem =
                rulesTemplateListResponse.result.ruletemplates.find(
                  (a) => a.ruletemplateid === ruleItem.ruletemplateid
                );

              // scoretype
              // "BINARY" = "Yes/No"
              // "VIOLATION_COUNT" = "Count"
              // "VIOLATION_FRACTION" = "0 - 100%"
              let scoring = "--";
              if (ruleTemplateItem.scoretype == "BINARY") {
                scoring = "Yes/No";
              } else if (ruleTemplateItem.scoretype == "VIOLATION_COUNT") {
                scoring = "Count";
              } else if (ruleTemplateItem.scoretype == "VIOLATION_FRACTION") {
                scoring = "0 - 100%";
              }

              return {
                description: ruleTemplateItem.description,
                categories: ruleTemplateItem.categories,
                name: ruleItem.name,
                ruletemplateid: ruleItem.ruletemplateid,
                scoring: scoring,
                source:
                  ruleTemplateItem.dataresources &&
                  ruleTemplateItem.dataresources.length > 0
                    ? ruleTemplateItem.dataresources[0].split(".")[0]
                    : "",
              };
            }
          );

          ruleDataCombined.map((rule) => {
            rule.categories.map((cat) => {
              if (!rulesByCategory[cat]) {
                rulesByCategory[cat] = [];
              }

              rulesByCategory[cat].push(rule);
            });
          });

          this.ruleOverviewData = rulesByCategory;

          if (JSON.stringify(rulesByCategory) === "{}") {
            this.error = getRuleOverviewMessage(this.$store.state.organization);
          }

          this.tableColumns = this.setTableColumns();

          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message:
              "rules.list + ruletemplates.listUsableRuleTemplates - Promise.all - catch",
            error: error,
          });
        });
    },
    getFixtureRules() {
      let rulesByCategory = {};
      ruleOverviewFixture.map((rule) => {
        if (!rulesByCategory[rule.name]) {
          rulesByCategory[rule.name] = [];
        }

        rulesByCategory[rule.name] = rule.data;
      });

      this.ruleOverviewData = rulesByCategory;
      this.tableColumns = this.setTableColumns();
      this.loading = false;
    },
    updateRulesData() {
      if (process.env.NODE_ENV === "demo") {
        return this.getFixtureRules();
      } else if (this.displayExample) {
        return this.getAllRuleTempletesForOrg();
      } else {
        return this.getAllActiveRulesForOrg();
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.ruleOverviewData = null;
      this.error = getRuleOverviewMessage(this.$store.state.organization);
      this.loading = false;
    },
    setTableColumns() {
      return [
        {
          title: "Rule",
          width: 200,
          render(row) {
            return h("p", { style: { margin: "0" } }, [row.name]);
          },
        },
        {
          title:
            process.env.NODE_ENV === "demo" ? "Business Value" : "Description",
          width: 300,
          render(row) {
            return h("p", { style: { margin: "0" } }, [
              process.env.NODE_ENV === "demo"
                ? row.businessValue
                : row.description,
            ]);
          },
        },

        {
          title: "Scoring",
          key: "scoring",
          width: 120,
          render(row) {
            return h("p", { style: { margin: "0" } }, [row.scoring]);
          },
        },
        {
          title: "Source of Data",
          width: 160,
          render(row) {
            return h(
              "p",
              {
                style: { margin: "0" },
              },
              [row.source ? row.source.toUpperCase() : ""]
            );
          },
        },
      ];
    },
  },
  watch: {
    $route: function () {
      // on route update force the data to reload
      this.updateRulesData();
    },
    "$store.state.organization": function (newOrg) {
      this.$nextTick(async () => {
        if (
          encodeURIComponent(this.$route.query.org) !==
          encodeURIComponent(newOrg)
        ) {
          router.push("/platform-governance/overview");
        } else if (this.$route.query.org === newOrg) {
          this.updateRulesData();
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
  },
  mounted() {
    if (this.$store.state.organization) {
      this.updateRulesData();
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
  data() {
    return {
      loading: true,
      error: null,
      NODE_ENV: process.env.NODE_ENV,
      id: this.$route.params.id,
      ruleOverviewData: reactive({}),
      tableColumns: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.site-detail {
  .caret {
    position: relative;
    top: -1px;
  }
  .table-wrapper {
    width: 100%;
    min-width: 700px;
  }

  .table-header {
    display: block;
    position: relative;
    margin-bottom: 16px;

    .title {
      min-width: auto;
      margin-right: 40px;
      position: relative;
      top: 4px;
    }
  }

  .widget {
    display: block;
  }

  .addRuleBtn {
    margin: 34px 0 0 auto;
    display: block;
    width: 110px;
  }
}
</style>
