<template>
  <div class="widget total-violations" :class="size">
    <h2 class="title">Total Violations</h2>
    <BigNumber
      v-if="scans && scans.length > 0 && scans[0].violation_count !== undefined"
      :number="scans[0].violation_count"
      class="total-violations-number"
    />
    <div
      class="total-violations-bottom"
      v-if="scans != null && scans.length >= 1"
    >
      <template v-if="scans.length > 1">
        <Delta
          :past_count="scans[1].violation_count"
          :current_count="scans[0].violation_count"
        />
      </template>
      <router-link
        :to="`/platform-governance/violations?org=${encodeURIComponent(
          this.$store.state.organization
        )}`"
        class="button"
        >View All</router-link
      >
    </div>
  </div>
</template>

<script>
import BigNumber from "@/components/Shared/BigNumber.vue";
import Delta from "@/components/Shared/Delta.vue";

export default {
  name: "TotalViolations",
  components: {
    BigNumber,
    Delta,
  },
  props: ["scans", "size"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.total-violations-number {
  max-width: 200px;
  width: 100%;
  padding: 20px 0;
}

.total-violations-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .button {
    flex-shrink: 0;
  }
}
</style>
