import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate"
import Cookies from "js-cookie";
import router from "@/router";
import AdtechService from "@/services/AdtechService";
import MetadataService from "@/services/MetadataService";
import CredentialService from "@/services/CredentialService";

// fixtures
import ScansFixture from "@/fixtures/scans.json";
import ContainersFixture from "@/fixtures/containers.json";
import ConsentRegionFixture from "@/fixtures/consentRegion.json";

// releases
import Release_1_0_0 from "@/fixtures/_releases/release_1_0_0.json";
import Release_1_1_0 from "@/fixtures/_releases/release_1_1_0.json";
import Release_1_1_1 from "@/fixtures/_releases/release_1_1_1.json";

import { getDashboardErrorMessage } from "@/ErrorMessaging";

// const persistedState = createPersistedState({
//   paths: ['userData']
// })

async function ipfPaginatedList(ipfService, method, params, resultDataKey) {
  let results = [];
  let currentPageToken = 0;
  while (currentPageToken !== null) {
    let currentParams = { ...params };
    if (currentPageToken) currentParams.pageToken = currentPageToken;
    let response = await ipfService.call(method, currentParams || {});
    if (response?.result[resultDataKey])
      results.push(...response.result[resultDataKey]);
    if (response?.result?.pageToken) {
      currentPageToken = response.result.pageToken;
    } else {
      currentPageToken = null;
    }
  }
  return results;
}

export default createStore({
  //plugins: [persistedState],
  state: {
    userData: null,
    users: null,
    scans: null,
    containers: null,
    consentTimePeriod: null,
    consentTimePeriodOptions: null,
    organization: null,
    organizationOptions: null,
    githubReleaseData: null,
  },
  getters: {
    isAdmin(state) {
      if (state.userData == null) return false;
      if (
        typeof state.userData.user.groups != "undefined" &&
        state.userData.user.groups.includes("admin")
      ) {
        return true;
      } else {
        return false;
      }
    },
    isUserLoggedIn(state) {
      if (state.userData == null) return false;
      return true;
    },
    getContainerMeta: (state) => (containerId, propertyName) => {
      const container =
        state.containers && !state.containers.error
          ? state.containers.find((c) => c.containerid === containerId)
          : null;
      return container ? container[propertyName] : null;
    },
  },
  mutations: {
    setUserData(state, userData) {
      Cookies.set("userData", JSON.stringify(userData), { expires: 1 });
      state.userData = userData;
    },
    logout(state) {
      Cookies.remove("userData");
      state.userData = null;
      router.push("/");
    },
    setUsers(state, users) {
      state.users = users;
    },
    setScans(state, scans) {
      state.scans = scans;
    },
    setContainers(state, containers) {
      state.containers = containers;
      let foundConsentMonitoringContainer =
        containers && !containers.error
          ? containers.find((elem) => !!elem.extservices?.consentMonitoring)
          : null;

      let consentMonitoringContainer = foundConsentMonitoringContainer;
      if (
        !foundConsentMonitoringContainer &&
        !containers.error &&
        containers.length > 0
      ) {
        consentMonitoringContainer = containers[0];
      }

      state.consentMonitoringContainer = consentMonitoringContainer;
    },
    setConsentTimePeriodOptions(state, timePeriodOptions) {
      state.consentTimePeriodOptions = timePeriodOptions;
    },
    setConsentTimePeriod(state, timePeriod) {
      state.consentTimePeriod = timePeriod;
    },
    setConsentRegionOptions(state, regionOptions) {
      state.consentRegionOptions = regionOptions;
    },
    setConsentRegion(state, region) {
      state.consentRegion = region;
    },
    setOrganizationOptions(state, organizationOptions) {
      state.organizationOptions = organizationOptions;
    },
    setOrganization(state, org) {
      state.organization = org;
    },
    setOrgHierarchy(state, orgHierarchy) {
      state.orgHierarchy = orgHierarchy;
    },
    setGithubReleaseData(state, githubReleaseData) {
      state.githubReleaseData = githubReleaseData;
    },
    setWhatsNewCount(state, whatsNewCount) {
      state.whatsNewCount = whatsNewCount;
    },
    setFeaturePermissions(state, featurePermissions) {
      state.featurePermissions = featurePermissions;
    },
  },
  actions: {
    getScans({ commit, state }) {
      if (state.organization && process.env.NODE_ENV !== "demo") {
        AdtechService.call("scans.list", {
          orgid: state.organization,
          limit: 10,
        })
          .then((response) => {
            if (response.error) {
              console.log("%cError", "color: white; background-color: red", {
                message:
                  "store/index.js - getScans - scans.list - error obj returned from api",
                error: response.error,
              });
              commit("setScans", {
                error: getDashboardErrorMessage(state.organization),
              });
              return Promise.resolve();
            }

            if (!response.result.scans || response.result.scans.length === 0) {
              commit("setScans", {
                error: getDashboardErrorMessage(state.organization),
              });
            } else {
              commit("setScans", response.result.scans);
            }
          })
          .catch((error) => {
            console.log("%cError", "color: white; background-color: red", {
              message: "store/index.js - getScans - scans.list - catch",
              error: error,
            });
            commit("setScans", {
              error: getDashboardErrorMessage(state.organization),
            });
          });
      } else if (process.env.NODE_ENV === "demo") {
        commit("setScans", ScansFixture);
      }
    },
    getContainers({ commit, state }) {
      if (state.organization && process.env.NODE_ENV !== "demo") {
        ipfPaginatedList(
          MetadataService,
          "analyticscontainers.list",
          { orgid: state.organization, includeChildOrgs: true },
          "containers"
        )
          .then((containers) => {
            if (containers.error) {
              console.log("%cError", "color: white; background-color: red", {
                message:
                  "store/index.js - getContainers - analyticscontainers.list - error obj returned from api",
                error: containers.error,
              });
              commit("setContainers", {
                error: getDashboardErrorMessage(state.organization),
              });
              return Promise.resolve();
            }

            commit("setContainers", containers);
          })
          .catch((error) => {
            console.log("%cError", "color: white; background-color: red", {
              message:
                "store/index.js - getContainers - analyticscontainers.list - catch",
              error: error,
            });
            commit("setContainers", {
              error: getDashboardErrorMessage(state.organization),
            });
          });
      } else if (process.env.NODE_ENV === "demo") {
        commit("setContainers", ContainersFixture);
      }
    },
    getConsentRegionOptions({ commit, state }) {
      if (
        (!state.containers ||
          state.containers.length === 0 ||
          state.containers.error) &&
        process.env.NODE_ENV !== "demo"
      ) {
        return;
      } else if (process.env.NODE_ENV === "demo") {
        return commit("setConsentRegionOptions", ConsentRegionFixture);
      }

      return [state.consentMonitoringContainer].forEach((container) => {
        MetadataService.call("runConsentMonitoringQuery", {
          containerid: container.containerid,
          query: `
          SELECT
            DISTINCT(scan_region) as scanRegion
          FROM __get_scanned_sites___;
          `,
        })
          .then((response) => {
            if (response.error || !response.result) {
              console.log("%cError", "color: white; background-color: red", {
                message:
                  "store/index.js - getConsentRegionOptions - runConsentMonitoringQuery - error obj returned from api / or no result obj returned",
                error: response.error,
                missingResultObject: !response.result,
              });
              return Promise.resolve();
            }

            const regions = response.result.queryResult.data;
            let regionOptions = [];
            regions.forEach((region) => {
              regionOptions.push({
                label: region.f[0].v,
                value: region.f[0].v,
              });
            });
            commit("setConsentRegionOptions", regionOptions);
          })
          .catch((error) => {
            console.log("%cError", "color: white; background-color: red", {
              message:
                "store/index.js - getConsentRegionOptions - runConsentMonitoringQuery - catch",
              error: error,
            });
          });
      });
    },
    getOrganizationOptions({ commit, state }) {
      // fake data for demo env
      if (process.env.NODE_ENV === "demo") {
        commit("setOrganizationOptions", [
          {
            label: "demo",
            value: "demo",
            role: "Admin",
          },
          {
            label: "example-brand-1",
            value: "example-brand-1",
            role: "Admin",
          },
          {
            label: "example-brand-2",
            value: "example-brand-2",
            role: "Admin",
          },
        ]);
        commit("setOrganization", "demo");
        commit("setOrgHierarchy", [
          {
            name: "example-brand-1",
            orgid: "example-brand-1",
            parent_orgid: "demo",
          },
          {
            name: "example-brand-2",
            orgid: "example-brand-2",
            parent_orgid: "demo",
          },
          {
            name: "demo",
            orgid: "demo",
            parent_orgid: "root",
          },
        ]);

        return;
      }

      const userId = state.userData.userid;
      const userPrimaryOrg = state.userData.user.primary_orgid;
      let orgRoles = [];

      // The API call userroles.getOrgsWithUserRoles returns
      // a list of all orgs the user has access to
      CredentialService.call("userroles.getOrgsWithUserRoles", {
        recurse: true,
      }).then((response) => {
        if (response.error) {
          console.log("%cError", "color: white; background-color: red", {
            message:
              "store/index.js - getOrganizationOptions - userroles.getOrgsWithUserRoles - error obj returned from api",
            error: response.error,
          });
          return Promise.resolve();
        }

        // now loop through each org the user has access to
        // and figure out what role level they have (Admin or Viewer)
        const promiseArray = response.result.orgids.map((org) => {
          return CredentialService.call("userroles.getEffective", {
            orgid: org,
            userid: userId,
          })
            .then((orgResponse) => {
              if (orgResponse.error) {
                console.log("%cError", "color: white; background-color: red", {
                  message:
                    "store/index.js - getOrganizationOptions - userroles.getOrgsWithUserRoles - error obj returned from api",
                  error: orgResponse.error,
                  info: `call for ${org} org`,
                });
                return Promise.resolve();
              }

              const role =
                orgResponse &&
                orgResponse.result &&
                orgResponse.result.roles &&
                orgResponse.result.roles.includes("admin")
                  ? "Admin"
                  : "Viewer";

              orgRoles.push({
                label: org,
                value: org,
                role: role,
              });
            })
            .catch((error) => {
              console.log("%cError", "color: white; background-color: red", {
                message:
                  "store/index.js - getOrganizationOptions - userroles.getOrgsWithUserRoles - catch",
                error: error,
                info: `call for ${org} org`,
              });
            });
        });

        return Promise.all(promiseArray)
          .then(() => {
            commit("setOrganizationOptions", orgRoles);
            const ADTECH_ORG = localStorage.getItem("ADTECH_ORG");
            // the initial org selected should be the user's primary org
            // only if no other org was previously selected
            let currentOrgSelected = ADTECH_ORG ? ADTECH_ORG : userPrimaryOrg;
            commit("setOrganization", currentOrgSelected);
          })
          .catch((error) => {
            console.log("%cError", "color: white; background-color: red", {
              message:
                "store/index.js - getOrganizationOptions - promiseArray - catch",
              error: error,
            });
          });
      });

      // also get the org hierarchy
      CredentialService.call("orgs.list", {
        limit: 1000,
        orgid: "root",
        includeChildOrgs: true,
      }).then((response) => {
        commit("setOrgHierarchy", response.result.orgs);
      });
    },
    getGithubReleaseData({ commit }) {
      const releaseData = [
        { ...Release_1_1_1 },
        { ...Release_1_1_0 },
        { ...Release_1_0_0 }
      ];

      const ADTECH_RELEASES = localStorage.getItem("ADTECH_RELEASES");
      let localStorageReleases = ADTECH_RELEASES
        ? JSON.parse(ADTECH_RELEASES)
        : [];
      let whatsNewCount = 0;
      if (localStorageReleases && localStorageReleases.length > 0) {
        const findIndex = releaseData.findIndex((i) => {
          return i && `${i.id}` === `${localStorageReleases[0]}`;
        });

        if (findIndex === -1) {
          whatsNewCount = releaseData.length;
        } else {
          whatsNewCount = findIndex;
        }
      } else {
        whatsNewCount = releaseData.length;
      }

      commit("setWhatsNewCount", whatsNewCount);
      commit("setGithubReleaseData", releaseData);
    },
    getFeaturePermissions({ commit, state }) {
      let featurePermissions = {
        tagConsent: true,
        platformGovernance: true,
        mediaPerformance: true,
      };

      if (
        !state.organizationOptions ||
        state.organizationOptions.length === 0
      ) {
        featurePermissions = {
          tagConsent: false,
          platformGovernance: false,
          mediaPerformance: false,
        };
      }

      commit("setFeaturePermissions", featurePermissions);
    },
  },
});
