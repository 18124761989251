<template>
  <SiteHeaderAndSidebar>
    <section class="workspace">
      <n-config-provider :theme="theme">
        <n-message-provider>
          <n-dialog-provider>
            <router-view />
          </n-dialog-provider>
        </n-message-provider>
      </n-config-provider>
    </section>
  </SiteHeaderAndSidebar>
</template>

<script>
import Cookies from "js-cookie";

import { NDialogProvider, NMessageProvider, NConfigProvider } from "naive-ui";
import SiteHeaderAndSidebar from "@/components/Shared/SiteHeaderAndSidebar.vue";

export default {
  name: "App",
  components: {
    NDialogProvider,
    NMessageProvider,
    NConfigProvider,
    SiteHeaderAndSidebar,
  },
  watch: {
    "$store.state.organization": function (newOrg) {
      // we also want to save this in local storage
      localStorage.setItem("ADTECH_ORG", newOrg);

      // update the container and scans
      this.$store.dispatch("getScans");
      this.$store.dispatch("getContainers");
    },
    "$store.state.containers": function () {
      // update the consent data
      this.$store.dispatch("getConsentRegionOptions");
    },
    "$store.state.userData": function (newUser, oldUser) {
      this.$nextTick(async () => {
        // get the organization upon login
        if (!oldUser && newUser && !this.$store.state.organization) {
          this.$store.dispatch("getOrganizationOptions");
          this.$store.dispatch("getGithubReleaseData");
        }
      });
    },
    "$store.state.organizationOptions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.organizationOptions) {
          // get the feature permissisons once org options are loaded
          this.$store.dispatch("getFeaturePermissions");
        }
      });
    },
  },
  created() {
    if (typeof Cookies.get("userData") != "undefined") {
      this.$store.commit("setUserData", JSON.parse(Cookies.get("userData")));
    }
    if (this.$store.getters.isUserLoggedIn) {
      this.$store.dispatch("getOrganizationOptions");
      this.$store.dispatch("getGithubReleaseData");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.workspace {
  display: flex;
  flex: 1 1 auto;
  padding: 2rem;

  @include media($small-lowest, down) {
    padding: 1.5rem;
  }
}
</style>
