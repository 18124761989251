<template>
  <div
    :class="`media-performance widget ${
      NODE_ENV !== 'demo' ? 'half' : 'third'
    }`"
    v-if="!error"
  >
    <h2 class="title">Media Performance</h2>
    <div v-if="error || loading">
      <!-- <div class="site-error" v-if="error || !loading">{{ error }}</div> -->
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>

    <div class="big-numbers" v-if="!error && !loading">
      <BigNumber
        :number="`$${formatLargeNumber(metrics.monthlyTotalSpend)}`"
        descriptor="Monthly Total Spend"
        numberSize="medium"
      />
      <BigNumber
        :number="`${formatLargeNumber(metrics.monthlyTotalImpressions)}`"
        descriptor="Monthly Total Impressions"
        numberSize="medium"
      />
      <BigNumber
        :number="`$${formatCurrency(metrics.cpm, 2)}`"
        descriptor="CPM"
        numberSize="medium"
      />
      <BigNumber
        :number="`${formatPercent(metrics.ctr, 4)}%`"
        descriptor="CTR"
        numberSize="medium"
      />
    </div>
    <router-link
      to="/media-performance/overview"
      class="button"
      v-if="!error && !loading"
    >
      View Details
    </router-link>
  </div>
</template>

<script>
import AdtechService from "@/services/AdtechService";
import BigNumber from "@/components/Shared/BigNumber.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getMediaPerformanceErrorMessage } from "@/ErrorMessaging";

export default {
  name: "TagConsentMonitoring",
  components: { BigNumber, LoadingSpinner },
  props: [],
  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      metrics: {
        monthlyTotalSpend: null,
        monthlyTotalImpressions: null,
        cpm: null,
        ctr: null,
      },
      getDataActive: false,
      activeDateRange: {},
      loading: true,
      error: null,
    };
  },
  methods: {
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.loading = false;
      this.error = getMediaPerformanceErrorMessage(
        this.$store.state.organization
      );
    },
    async getData() {
      if (this.getDataActive) return; // don't start new calls if there is already an active call

      // reset loading and error states
      this.getDataActive = true;
      if (!this.loading) {
        this.loading = true;
      }
      if (this.error) {
        this.error = null;
      }

      await this.getActiveDateRange();
      if (this.error) return; // stop calls if there was an error
      await this.getMetrics();
    },
    getActiveDateRange() {
      // get available date ranges
      return AdtechService.call("pm1.getAvailableDateRanges", {
        orgid: this.$store.state.organization,
      })
        .then((dateRange_response) => {
          if (dateRange_response.error) {
            this.triggerError({
              message:
                "pm1.getAvailableDateRanges - dashboard widget - error obj returned from api",
              error: dateRange_response.error,
            });
            return Promise.resolve();
          }

          if (dateRange_response.result.dateRanges.length === 0) {
            this.triggerError({
              message:
                "pm1.getAvailableDateRanges - dashboard widget - returned dateRanges length is 0",
            });
            return Promise.resolve();
          }

          const dateRanges = dateRange_response.result.dateRanges;
          // sort by date, most recent first
          dateRanges.sort(function (a, b) {
            return new Date(a.dateRangeBegin) - new Date(b.dateRangeBegin);
          });
          // set the active date range as the most recent
          this.activeDateRange = dateRanges[dateRanges.length - 1];
        })
        .catch((error) => {
          this.triggerError({
            message: "pm1.getAvailableDateRanges - dashboard widget - catch",
            error: error,
          });
        });
    },
    getMetrics() {
      if (process.env.NODE_ENV === "demo") {
        this.metrics = {
          monthlyTotalSpend: 12234434,
          monthlyTotalImpressions: 18762635,
          cpm: 3.21,
          ctr: 2.4,
        };
        return Promise.resolve();
      }

      AdtechService.call("pm1.getMetrics", {
        orgid: this.$store.state.organization,
        dateRangeBegin: this.activeDateRange.dateRangeBegin,
        dateRangeEnd: this.activeDateRange.dateRangeEnd,
      })
        .then((response) => {
          if (response.error) {
            this.triggerError({
              message:
                "pm1.getMetrics (current month) - error obj returned from api",
              error: response.error,
            });
            return Promise.resolve();
          }
          const metrics_response = response.result.overall;
          this.metrics = {
            monthlyTotalSpend: metrics_response.revenue,
            monthlyTotalImpressions: metrics_response.impressions,
            cpm: metrics_response.cpm,
            ctr: metrics_response.ctr,
          };
          this.getDataActive = false;
          this.loading = false;
          return Promise.resolve();
        })
        .catch((error) => {
          this.triggerError({
            message: "pm1.getMetrics - dashboard widget - catch",
            error: error,
          });
        });
    },
    formatLargeNumber(value) {
      // Nine Zeroes for Billions
      return Math.abs(Number(value)) >= 1.0e9
        ? (Math.abs(Number(value)) / 1.0e9).toFixed(0) + " Bil"
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(0) + " Mil"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? (Math.abs(Number(value)) / 1.0e3).toFixed(0) + " K"
        : this.formatCurrency(value, 0);
    },
    formatCurrency(value, dec) {
      let formatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: dec,
        minimumFractionDigits: dec,
      });
      const newValue =
        value > 0 ? ((value * 1000) / 1000).toFixed(dec) : "0.0000";
      return `${formatter.format(newValue)}`;
    },
    formatPercent(value, dec) {
      return value.toFixed(dec);
    },
  },
  mounted() {
    if (this.$store.state.organization) {
      this.getData();
    }
  },
  watch: {
    "$store.state.organization": function () {
      this.$nextTick(async () => {
        if (this.$store.state.organization) {
          this.getData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.media-performance {
  h2 {
    margin-top: 0;
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
  }

  .button {
    margin: 20px 0 0 auto;
    display: block;
    width: 135px;
    text-align: center;
  }

  .big-numbers {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;
    margin-top: 15px;

    > div {
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .smaller {
      > div {
        &:first-child {
          font-size: 26px;
        }
      }
    }
  }
}
</style>
