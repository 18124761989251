<template>
  <div class="widget improvement-monitoring" :class="size">
    <h2 class="title">Most Improved</h2>
    <div
      v-if="this.$store.state.scans && this.$store.state.scans.length > 1"
      class="table"
    >
      <div v-for="container in improvedSites" :key="container.containerId">
        <div class="website">{{ container.name }}</div>
        <Delta
          :past_count="container.past_fail_count"
          :current_count="container.fail_count"
          class="delta"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Delta from "@/components/Shared/Delta.vue";
import AdtechService from "@/services/AdtechService.js";
import { getDelta } from "@/Helpers.js";

// fixtures
import containersFixture from "@/fixtures/containers.json";
import containersPastFixture from "@/fixtures/containersPast.json";

export default {
  name: "TotalViolations",
  props: ["size"],
  components: {
    Delta,
  },
  watch: {
    "$store.state.scans": function () {
      this.$nextTick(async () => {
        if (this.$store.state.scans && this.$store.state.containers) {
          this.getImprovedSites();
        }
      });
    },
    "$store.state.containers": function () {
      this.$nextTick(async () => {
        if (this.$store.state.scans && this.$store.state.containers) {
          this.getImprovedSites();
        }
      });
    },
  },
  data() {
    return {
      improvedSites: [],
    };
  },
  methods: {
    formatData(past, current) {
      const containerArray = current.map((container, index) => {
        return {
          containerid: container.containerid,
          fail_count: container.fail_count,
          past_fail_count: past[index] ? past[index].fail_count : 0,
          delta: getDelta(
            past[index] ? past[index].fail_count : 0,
            container.fail_count
          ),
        };
      });

      // sort the array by delta diff value
      containerArray.sort(function (a, b) {
        return a.delta.difference - b.delta.difference;
      });

      // we only want the top 5 improved containers
      const getTopFive = containerArray.slice(0, 5);

      // get the container names for the top 5 improved containers
      const topFiveWithNames = getTopFive.map((container) => {
        const containerInfo = this.$store.state.containers
          ? this.$store.state.containers.find(
              (c) => c.containerid === container.containerid
            )
          : null;
        return {
          ...container,
          name: containerInfo ? containerInfo.name : null,
        };
      });
      return topFiveWithNames;
    },
    getImprovedSites() {
      // TODO: error handling at component level
      if (
        this.$store.state.scans &&
        this.$store.state.scans[0] &&
        this.$store.state.scans[1] &&
        process.env.NODE_ENV !== "demo"
      ) {
        AdtechService.call("reports.scanContainers", {
          scanid: this.$store.state.scans[0].scanid,
        }).then((response) => {
          if (this.$store.state.scans[1]) {
            const currentScanContainers = response.result.scanContainers;
            AdtechService.call("reports.scanContainers", {
              scanid: this.$store.state.scans[1].scanid,
            }).then((pastResponse) => {
              const pastScanContainers = pastResponse.result.scanContainers;

              this.improvedSites = this.formatData(
                pastScanContainers,
                currentScanContainers
              );
            });
          }
        });
      } else if (process.env.NODE_ENV === "demo") {
        this.improvedSites = this.formatData(
          containersFixture,
          containersPastFixture
        );
      }
    },
  },
  mounted() {
    if (this.$store.state.scans && this.$store.state.containers) {
      this.getImprovedSites();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.improvement-monitoring {
  .title {
    width: 100%;
    text-align: left;
  }
  .table {
    width: 100%;
    max-width: 360px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    .website {
      width: 140px;
      color: $navy;
      font-size: 16px;
      line-height: 20px;
    }
    .delta {
      width: calc(100% - 140px);
      text-align: right;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
